import React from "react";
import Helmet from "react-helmet";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
// import TopBanner from "../../components/pricingnew/topbanner";
import LegacyStandard from "../../components/pricingnew/legacystandard";

const ogImage = {
  ogImage:
    "https://res.cloudinary.com/dh8fp23nd/image/upload/v1677064058/main-web/legacy-pricing_ftunhs.jpg",
};

const canonicalUrl = "https://hasura.io/pricing/legacy/";

const Pricing = props => (
  <Layout location={props.location}>
    <Seo
      title="Pricing for the deprecated Standard plan on Hasura Cloud  - Hasura"
      description="Reference for the legacy Standard plan that was deprecated as a part of the pricing changes announced on February 1, 2023."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <LegacyStandard location={props.location} />
  </Layout>
);

export default Pricing;
