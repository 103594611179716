import hasuraFree from "../imagescommon/hasura-free.svg";
import hasuraStandard from "../imagescommon/hasura-standard.svg";
import hasuraCe from "../imagescommon/hasura-ce.svg";
import hasuraEe from "../imagescommon/hasura-ee.svg";

const mobileCloudState = [
  {
    id: "free-feature",
    imgSrc: hasuraFree,
    title: "Forever $0",
    type: "Free",
    typeImg:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1675170349/main-web/icons/home-smile_g5blcm.svg",
    headerBgClass: "free",
    desc: "For experimentation, learning, and early development.",
    list: [
      "Connect 2 databases per project",
      "Up to 100MB data passthrough/month",
      "Up to 3M API requests/month",
      "Instant GraphQL and REST APIs with role-based authorization",
      "Premium connectors, including Snowflake, SQL Server, and more",
    ],
    titleType: "CLOUD",
    btnText: "Get Started for Free",
    btnLink:
      "https://cloud.hasura.io/signup?pg=pricing&plcmt=body&cta=get-started-for-free&tech=default",
    btnVariant: "dark_price",
    featureList: [
      {
        listTitle: "Data APIs",
        list: [
          {
            featureType: "Instant GraphQL APIs for CRUD operations",
            isDescShow: false,
            // desc: "Instant GraphQL APIs on your databases.",
          },
          {
            featureType: "RESTified endpoints",
            isDescShow: false,
            // desc: "Receive real-time updates with live queries",
          },
          {
            featureType: "GraphQL subscriptions",
            isDescShow: false,
            // desc: "Create idiomatic REST endpoints based on GraphQL templates.",
          },
          {
            featureType: "Remote Schemas",
            isDescShow: false,
            // desc: "Define granular role-based access control rules for every field in your GraphQL schema, and to any row or column in your database",
          },
          {
            featureType: "Remote joins",
            isDescShow: false,
            // desc: "Invoke custom logic using webhooks with database triggers",
          },
          {
            featureType: "Actions",
            isDescShow: false,
            // desc: "Easily connect and configure your data sources with an intuitive GUI",
          },
          {
            featureType: "Event triggers & scheduled triggers",
            isDescShow: false,
            // desc: "Command line tool to manage your Hasura projects and migrations",
          },
          {
            featureType: "Admin console",
            isDescShow: false,
            // desc: "Add external GraphQL APIs and access with with Hasura's built in authorization",
          },
          {
            featureType: "GraphiQL query and mutation designer",
            isDescShow: false,
            // desc: "Join data from disparate data sources such as databases and other remote data sources",
          },
          {
            featureType: "CLI",
            isDescShow: false,
            // desc: "Admin API to manage the Hasura schema and metadata.",
          },
          {
            featureType: "Metadata APIs",
            isDescShow: false,
            // desc: "Migrate project metadata and database schema to different environments",
          },
          {
            featureType: "Schema migrations",
            isDescShow: false,
          },
          {
            featureType: "Apollo Federation v1 support",
            isDescShow: false,
          },
          {
            featureType: "Import Action from OpenAPI Specification (OAS)",
            isDescShow: false,
          },
          {
            featureType: "Connected databases per project - Up to 2",
            isDescShow: false,
          },
          {
            featureType: "Projects per account - Up to 3",
            isDescShow: false,
          },
          {
            featureType: "Data passthrough - Up to 100 MB / month",
            isDescShow: false,
          },
          {
            featureType:
              "API request count (includes websocket messages) - Up to 3 million / month",
            isDescShow: false,
          },
          {
            featureType: "API request duration - Up to 60 seconds",
            isDescShow: false,
          },
          {
            featureType: "Concurrent websocket connections - Up to 10",
            isDescShow: false,
          },
          {
            featureType: "Database connection pool idle timeout - Preset by Hasura",
            isDescShow: false,
          },
          {
            featureType: "Database connection pool size - Preset by Hasura",
            isDescShow: false,
          },
          {
            featureType:
              "DB connection pool minimum number of connections to keep-alive - Preset by Hasura",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Data connectors",
        list: [
          {
            featureType: "PostgreSQL",
            isDescShow: false,
          },
          {
            featureType: "Snowflake",
            isDescShow: false,
          },
          {
            featureType: "Google BigQuery",
            isDescShow: false,
          },
          {
            featureType: "Amazon Athena",
            isDescShow: false,
          },
          {
            featureType: "Microsoft SQL Server",
            isDescShow: false,
          },
          {
            featureType: "CockroachDB",
            isDescShow: false,
          },
          {
            featureType: "Google AlloyDB",
            isDescShow: false,
          },
          {
            featureType: "Citus / Hyperscale",
            isDescShow: false,
          },
          {
            featureType: "SQLite(self-hosted)",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Performance",
        list: [
          {
            featureType: "Query cache - (max 100 MB)",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Security",
        list: [
          {
            featureType: "Role-based authorization",
            isDescShow: false,
          },
          {
            featureType: "Collaborator management",
            isDescShow: false,
          },
          {
            featureType: "Role-based API timeout - Up to 60 secs",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Infrastructure",
        list: [
          {
            featureType: "Cloud providers: AWS, Google Cloud",
            isDescShow: false,
          },
          {
            featureType: "Region availability - 12 (AWS) 5 (Google Cloud)",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "CI/CD integrations",
        list: [
          {
            featureType: "GitHub integration",
            isDescShow: false,
          },
          {
            featureType: "Preview apps",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Compliance",
        list: [
          {
            featureType: "SOC 2 Type 2",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Support",
        list: [
          {
            featureType: "Community support",
            isDescShow: false,
          },
          {
            featureType: "Email/ticketed support",
            isDescShow: false,
          },
        ],
      },
    ],
  },
  {
    id: "standard-feature",
    imgSrc: hasuraStandard,
    title: "Starts at $1.50/active hour",
    type: "Professional",
    typeImg:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1675171039/main-web/icons/building-01_vyo6gm.svg",
    headerBgClass: "professional",
    desc: "For low-scale production APIs.",
    list: [
      "Unlimited databases per project",
      "Unlimited data passthrough at $0.13/GB",
      "Up to 6M API requests/month",
      "Performance tuning (query caching and read replicas)",
      "Observability data and integrations",
      "Core security features (for eg. role-based API limits and allow lists)",
    ],
    titleType: "CLOUD",
    btnText: "Start on Free Tier",
    btnLink:
      "https://cloud.hasura.io/signup?pg=pricing&plcmt=body&cta=start-on-free-tier&tech=default",
    btnVariant: "neutral_400",
    featureList: [
      {
        listTitle: "Data APIs",
        list: [
          {
            featureType: "Instant GraphQL APIs for CRUD operations",
            isDescShow: false,
            // desc: "Instant GraphQL APIs on your databases.",
          },
          {
            featureType: "RESTified endpoints",
            isDescShow: false,
            // desc: "Receive real-time updates with live queries",
          },
          {
            featureType: "GraphQL subscriptions",
            isDescShow: false,
            // desc: "Create idiomatic REST endpoints based on GraphQL templates.",
          },
          {
            featureType: "Remote Schemas",
            isDescShow: false,
            // desc: "Define granular role-based access control rules for every field in your GraphQL schema, and to any row or column in your database",
          },
          {
            featureType: "Remote joins",
            isDescShow: false,
            // desc: "Invoke custom logic using webhooks with database triggers",
          },
          {
            featureType: "Actions",
            isDescShow: false,
            // desc: "Easily connect and configure your data sources with an intuitive GUI",
          },
          {
            featureType: "Event triggers & scheduled triggers",
            isDescShow: false,
            // desc: "Command line tool to manage your Hasura projects and migrations",
          },
          {
            featureType: "Admin console",
            isDescShow: false,
            // desc: "Add external GraphQL APIs and access with with Hasura's built in authorization",
          },
          {
            featureType: "GraphiQL query and mutation designer",
            isDescShow: false,
            // desc: "Join data from disparate data sources such as databases and other remote data sources",
          },
          {
            featureType: "CLI",
            isDescShow: false,
            // desc: "Admin API to manage the Hasura schema and metadata.",
          },
          {
            featureType: "Metadata APIs",
            isDescShow: false,
            // desc: "Migrate project metadata and database schema to different environments",
          },
          {
            featureType: "Schema migrations",
            isDescShow: false,
          },
          {
            featureType: "Apollo Federation v1 support",
            isDescShow: false,
          },
          {
            featureType: "Import Action from OpenAPI Specification (OAS)",
            isDescShow: false,
          },
          {
            featureType: "Connected databases per project - No limit",
            isDescShow: false,
          },
          {
            featureType: "Projects per account - No limit",
            isDescShow: false,
          },
          {
            featureType: "Data passthrough - Unlimited at $0.13/GB",
            isDescShow: false,
          },
          {
            featureType: "API request count (includes websocket messages) - No limit",
            isDescShow: false,
          },
          {
            featureType: "API request duration - Up to 120 seconds",
            isDescShow: false,
          },
          {
            featureType: "Concurrent websocket connections - Up to 100",
            isDescShow: false,
          },
          {
            featureType: "Database connection pool idle timeout - User-configured",
            isDescShow: false,
          },
          {
            featureType: "Database connection pool size - User-configured",
            isDescShow: false,
          },
          {
            featureType:
              "DB connection pool minimum number of connections to keep-alive - User-configured",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Data connectors",
        list: [
          {
            featureType: "PostgreSQL",
            isDescShow: false,
          },
          {
            featureType: "Snowflake",
            isDescShow: false,
          },
          {
            featureType: "Google BigQuery",
            isDescShow: false,
          },
          {
            featureType: "Amazon Athena",
            isDescShow: false,
          },
          {
            featureType: "Microsoft SQL Server",
            isDescShow: false,
          },
          {
            featureType: "CockroachDB",
            isDescShow: false,
          },
          {
            featureType: "Google AlloyDB",
            isDescShow: false,
          },
          {
            featureType: "Citus / Hyperscale",
            isDescShow: false,
          },
          {
            featureType: "SQLite(self-hosted)",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Performance",
        list: [
          {
            featureType: "Query cache - (max 1 GB)",
            isDescShow: false,
          },
          {
            featureType: "Read replicas - (max 3)",
            isDescShow: false,
          },
          {
            featureType: "Dynamic database connection routing - (max 1)",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Security",
        list: [
          {
            featureType: "Role-based authorization",
            isDescShow: false,
          },
          {
            featureType: "GraphQL operations allow list",
            isDescShow: false,
          },
          {
            featureType: "TLS allow list",
            isDescShow: false,
          },
          {
            featureType: "Role-based API depth limit",
            isDescShow: false,
          },
          {
            featureType: "Role-based API node limit",
            isDescShow: false,
          },
          {
            featureType: "Role-based API rate limit",
            isDescShow: false,
          },
          {
            featureType: "Role-based API timeout - Up to 120 secs",
            isDescShow: false,
          },
          {
            featureType: "Disable GraphQL introspection",
            isDescShow: false,
          },
          {
            featureType: "Multiple JWT secrets",
            isDescShow: false,
          },
          {
            featureType: "Multiple admin keys",
            isDescShow: false,
          },
          {
            featureType: "Collaborator management",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Observability",
        list: [
          {
            featureType: "Metrics, logs, and traces",
            isDescShow: false,
          },
          {
            featureType:
              "Observability integration (such as Datadog, New Relic, Azure Monitor, Prometheus and OpenTelemetry",
            isDescShow: false,
          },
          {
            featureType: "Metrics API access",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Infrastructure",
        list: [
          {
            featureType: "Cloud providers: AWS, Google Cloud",
            isDescShow: false,
          },
          {
            featureType: "Region availability - 12 (AWS) 5 (Google Cloud)",
            isDescShow: false,
          },
          {
            featureType: "Custom domain (DNS CNAME)",
            isDescShow: false,
          },
          {
            featureType: "Elastic connection pooling",
            isDescShow: false,
          },
          {
            featureType: "Uptime SLA  - 99.90%",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "CI/CD integrations",
        list: [
          {
            featureType: "GitHub integration",
            isDescShow: false,
          },
          {
            featureType: "Preview apps",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Compliance",
        list: [
          {
            featureType: "SOC 2 Type 2",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Support",
        list: [
          {
            featureType: "Community support",
            isDescShow: false,
          },
          {
            featureType: "Email/ticketed support",
            isDescShow: false,
          },
          {
            featureType: "Expert Hasura technical support",
            isDescShow: false,
          },
        ],
      },
    ],
  },
  {
    id: "standard-enterprise",
    imgSrc: hasuraEe,
    title: "Custom pricing",
    type: "Enterprise",
    typeImg:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1675170349/main-web/icons/building-07_wzh2fa.svg",
    headerBgClass: "enterprise",
    desc: "For high-scale or mission-critical production APIs with security and compliance needs.",
    list: [
      "Unlimited databases per project",
      "Unlimited data passthrough at $0.13/GB",
      "Unlimited API request/month",
      "Dedicated infrastructure and VPC peering",
      "Unlimited query caching and read replicas",
      "Advanced security (eg. SSO) and compliance",
      "Expert 24x7 technical support, with customizable SLAs",
    ],
    titleType: "CLOUD",
    btnText: "Contact Sales",
    btnLink: "/contact-us/?type=hasuraenterprise",
    btnVariant: "neutral_400",
    internalLink: true,
    featureList: [
      {
        listTitle: "Data APIs",
        list: [
          {
            featureType: "Instant GraphQL APIs for CRUD operations",
            isDescShow: false,
            // desc: "Instant GraphQL APIs on your databases.",
          },
          {
            featureType: "RESTified endpoints",
            isDescShow: false,
            // desc: "Receive real-time updates with live queries",
          },
          {
            featureType: "GraphQL subscriptions",
            isDescShow: false,
            // desc: "Create idiomatic REST endpoints based on GraphQL templates.",
          },
          {
            featureType: "Remote Schemas",
            isDescShow: false,
            // desc: "Define granular role-based access control rules for every field in your GraphQL schema, and to any row or column in your database",
          },
          {
            featureType: "Remote joins",
            isDescShow: false,
            // desc: "Invoke custom logic using webhooks with database triggers",
          },
          {
            featureType: "Actions",
            isDescShow: false,
            // desc: "Easily connect and configure your data sources with an intuitive GUI",
          },
          {
            featureType: "Event triggers & scheduled triggers",
            isDescShow: false,
            // desc: "Command line tool to manage your Hasura projects and migrations",
          },
          {
            featureType: "Admin console",
            isDescShow: false,
            // desc: "Add external GraphQL APIs and access with with Hasura's built in authorization",
          },
          {
            featureType: "GraphiQL query and mutation designer",
            isDescShow: false,
            // desc: "Join data from disparate data sources such as databases and other remote data sources",
          },
          {
            featureType: "CLI",
            isDescShow: false,
            // desc: "Admin API to manage the Hasura schema and metadata.",
          },
          {
            featureType: "Metadata APIs",
            isDescShow: false,
            // desc: "Migrate project metadata and database schema to different environments",
          },
          {
            featureType: "Schema migrations",
            isDescShow: false,
          },
          {
            featureType: "Apollo Federation v1 support",
            isDescShow: false,
          },
          {
            featureType: "Import Action from OpenAPI Specification (OAS)",
            isDescShow: false,
          },
          {
            featureType: "Connected databases per project - No limit",
            isDescShow: false,
          },
          {
            featureType: "Projects per account - No limit",
            isDescShow: false,
          },
          {
            featureType: "Data passthrough - Unlimited at $0.13/GB",
            isDescShow: false,
          },
          {
            featureType: "API request count (includes websocket messages) - No limit",
            isDescShow: false,
          },
          {
            featureType: "API request duration - No limit",
            isDescShow: false,
          },
          {
            featureType: "Concurrent websocket connections - No limit",
            isDescShow: false,
          },
          {
            featureType: "Database connection pool idle timeout - User-configured",
            isDescShow: false,
          },
          {
            featureType: "Database connection pool size - User-configured",
            isDescShow: false,
          },
          {
            featureType:
              "DB connection pool minimum number of connections to keep-alive - User-configured",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Data connectors",
        list: [
          {
            featureType: "PostgreSQL",
            isDescShow: false,
          },
          {
            featureType: "Snowflake",
            isDescShow: false,
          },
          {
            featureType: "Google BigQuery",
            isDescShow: false,
          },
          {
            featureType: "Amazon Athena",
            isDescShow: false,
          },
          {
            featureType: "Microsoft SQL Server",
            isDescShow: false,
          },
          {
            featureType: "CockroachDB",
            isDescShow: false,
          },
          {
            featureType: "Google AlloyDB",
            isDescShow: false,
          },
          {
            featureType: "Citus / Hyperscale",
            isDescShow: false,
          },
          {
            featureType: "SQLite(self-hosted)",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Performance",
        list: [
          {
            featureType: "Query cache - (no limit)",
            isDescShow: false,
          },
          {
            featureType: "Read replicas - (no limit)",
            isDescShow: false,
          },
          {
            featureType: "Dynamic database connection routing - (no limit)",
            isDescShow: false,
          },
          {
            featureType: "Auto cleanup of event triggers",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Security",
        list: [
          {
            featureType: "Role-based authorization",
            isDescShow: false,
          },
          {
            featureType: "GraphQL operations allow list",
            isDescShow: false,
          },
          {
            featureType: "TLS allow list",
            isDescShow: false,
          },
          {
            featureType: "Role-based API depth limit",
            isDescShow: false,
          },
          {
            featureType: "Role-based API node limit",
            isDescShow: false,
          },
          {
            featureType: "Role-based API rate limit - (User- configured)",
            isDescShow: false,
          },
          {
            featureType: "Role-based API timeout - Configurable limit",
            isDescShow: false,
          },
          {
            featureType: "Disable GraphQL introspection",
            isDescShow: false,
          },
          {
            featureType: "Multiple JWT secrets",
            isDescShow: false,
          },
          {
            featureType: "Multiple admin keys",
            isDescShow: false,
          },
          {
            featureType: "Collaborator management",
            isDescShow: false,
          },
          {
            featureType: "SSO (SAML)",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Observability",
        list: [
          {
            featureType: "Metrics, logs, and traces",
            isDescShow: false,
          },
          {
            featureType:
              "Observability integration (such as Datadog, New Relic, Azure Monitor, Prometheus and OpenTelemetry",
            isDescShow: false,
          },
          {
            featureType: "Metrics API access",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Infrastructure",
        list: [
          {
            featureType: "Cloud providers: AWS, Google Cloud",
            isDescShow: false,
          },
          {
            featureType:
              "Region availability - 12 (AWS) 5 (Google Cloud) - Additional regions available on request.",
            isDescShow: false,
          },
          {
            featureType: "Custom domain (DNS CNAME)",
            isDescShow: false,
          },
          {
            featureType: "Elastic connection pooling",
            isDescShow: false,
          },
          {
            featureType: "Deployment autoscaling",
            isDescShow: false,
          },
          {
            featureType: "Dedicated cluster",
            isDescShow: false,
          },
          {
            featureType: "Uptime SLA  - 99.95%",
            isDescShow: false,
          },
          {
            featureType: "Failover for high availability",
            isDescShow: false,
          },
          {
            featureType: "VPC peering  - 1 included ($250/month per additional VPC)",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "CI/CD integrations",
        list: [
          {
            featureType: "GitHub integration",
            isDescShow: false,
          },
          {
            featureType: "Preview apps",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Compliance",
        list: [
          {
            featureType: "SOC 2 Type 2",
            isDescShow: false,
          },
          {
            featureType: "Data Processing Agreement (GDPR)",
            isDescShow: false,
          },
          {
            featureType: "Business Associate Agreement (BAA)",
            isDescShow: false,
          },
          {
            featureType: "HIPAA compliance",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Support",
        list: [
          {
            featureType: "Community support",
            isDescShow: false,
          },
          {
            featureType: "Email/ticketed support",
            isDescShow: false,
          },
          {
            featureType: "Priority response SLAs",
            isDescShow: false,
          },
          {
            featureType: "Expert Hasura technical support",
            isDescShow: false,
          },
          {
            featureType: "Onboarding program",
            isDescShow: false,
          },
          {
            featureType: "Named customer success manager - Available",
            isDescShow: false,
          },
        ],
      },
    ],
  },
];

const mobileOnPremState = [
  {
    id: "community-edition",
    imgSrc: hasuraCe,
    title: "Free",
    type: "Community",
    typeImg:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1675174647/main-web/icons/users-01_ebipae.svg",
    headerBgClass: "hasCe",
    desc: "For experimentation, learning, and early development.",
    list: [
      "Open source",
      "Instant GraphQL and REST APIs with role-based authorization",
      "PostgreSQL connector",
    ],
    titleType: "SELF-HOSTED",
    btnText: "Download free",
    btnLink: "https://hasura.io/docs/latest/getting-started/docker-simple/",
    btnVariant: "dark_price",
    featureList: [
      {
        listTitle: "Data APIs",
        list: [
          {
            featureType: "Instant GraphQL APIs for CRUD operations",
            isDescShow: false,
            // desc: "Instant GraphQL APIs on your databases.",
          },
          {
            featureType: "RESTified endpoints",
            isDescShow: false,
            // desc: "Receive real-time updates with live queries",
          },
          {
            featureType: "GraphQL subscriptions",
            isDescShow: false,
            // desc: "Create idiomatic REST endpoints based on GraphQL templates.",
          },
          {
            featureType: "Remote Schemas",
            isDescShow: false,
            // desc: "Define granular role-based access control rules for every field in your GraphQL schema, and to any row or column in your database",
          },
          {
            featureType: "Remote joins",
            isDescShow: false,
            // desc: "Invoke custom logic using webhooks with database triggers",
          },
          {
            featureType: "Actions",
            isDescShow: false,
            // desc: "Easily connect and configure your data sources with an intuitive GUI",
          },
          {
            featureType: "Event triggers & scheduled triggers",
            isDescShow: false,
            // desc: "Command line tool to manage your Hasura projects and migrations",
          },
          {
            featureType: "Admin console",
            isDescShow: false,
            // desc: "Add external GraphQL APIs and access with with Hasura's built in authorization",
          },
          {
            featureType: "GraphiQL query and mutation designer",
            isDescShow: false,
            // desc: "Join data from disparate data sources such as databases and other remote data sources",
          },
          {
            featureType: "CLI",
            isDescShow: false,
            // desc: "Admin API to manage the Hasura schema and metadata.",
          },
          {
            featureType: "Metadata APIs",
            isDescShow: false,
            // desc: "Migrate project metadata and database schema to different environments",
          },
          {
            featureType: "Schema migrations",
            isDescShow: false,
            // desc: "Migrate project metadata and database schema to different environments",
          },
          {
            featureType: "Apollo Federation v1 support",
            isDescShow: false,
            // desc: "Migrate project metadata and database schema to different environments",
          },
        ],
      },
      {
        listTitle: "Data connectors",
        list: [
          {
            featureType: "PostgreSQL",
            isDescShow: false,
          },
          {
            featureType: "Google BigQuery*",
            isDescShow: false,
          },
          {
            featureType: "Microsoft SQL Server*",
            isDescShow: false,
          },
          {
            featureType: "CockroachDB*",
            isDescShow: false,
          },
          {
            featureType: "Google AlloyDB*",
            isDescShow: false,
          },
          {
            featureType: "Citus / Hyperscale*",
            isDescShow: false,
          },
          {
            featureType: "SQLite",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Security",
        list: [
          {
            featureType: "Role-based authorization",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Observability",
        list: [
          {
            featureType: "Observability data - Logs only",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Support",
        list: [
          {
            featureType: "Community support",
            isDescShow: false,
          },
        ],
      },
    ],
  },
  {
    id: "enterprise-edition",
    imgSrc: hasuraEe,
    title: "Based on capacity",
    type: "Enterprise",
    typeImg:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1675170349/main-web/icons/building-07_wzh2fa.svg",
    headerBgClass: "hasEe",
    desc: "For high-scale or mission-critical production APIs with security and compliance needs",
    list: [
      "Premium connectors, including Snowflake, SQL Server, and more",
      "Performance tuning (query caching and read-replicas)",
      "Observability data and integrations",
      "Security, including role-based API limits, SSO, and more",
    ],
    titleType: "SELF-HOSTED",
    btnText: "Contact Sales",
    btnLink: "/contact-us/",
    btnVariant: "neutral_400",
    internalLink: true,
    featureList: [
      {
        listTitle: "Data APIs",
        list: [
          {
            featureType: "Instant GraphQL APIs for CRUD operations",
            isDescShow: false,
            // desc: "Instant GraphQL APIs on your databases.",
          },
          {
            featureType: "RESTified endpoints",
            isDescShow: false,
            // desc: "Receive real-time updates with live queries",
          },
          {
            featureType: "GraphQL subscriptions",
            isDescShow: false,
            // desc: "Create idiomatic REST endpoints based on GraphQL templates.",
          },
          {
            featureType: "Remote Schemas",
            isDescShow: false,
            // desc: "Define granular role-based access control rules for every field in your GraphQL schema, and to any row or column in your database",
          },
          {
            featureType: "Remote joins",
            isDescShow: false,
            // desc: "Invoke custom logic using webhooks with database triggers",
          },
          {
            featureType: "Actions",
            isDescShow: false,
            // desc: "Easily connect and configure your data sources with an intuitive GUI",
          },
          {
            featureType: "Event triggers & scheduled triggers",
            isDescShow: false,
            // desc: "Command line tool to manage your Hasura projects and migrations",
          },
          {
            featureType: "Admin console",
            isDescShow: false,
            // desc: "Add external GraphQL APIs and access with with Hasura's built in authorization",
          },
          {
            featureType: "GraphiQL query and mutation designer",
            isDescShow: false,
            // desc: "Join data from disparate data sources such as databases and other remote data sources",
          },
          {
            featureType: "CLI",
            isDescShow: false,
            // desc: "Admin API to manage the Hasura schema and metadata.",
          },
          {
            featureType: "Metadata APIs",
            isDescShow: false,
            // desc: "Migrate project metadata and database schema to different environments",
          },
          {
            featureType: "Schema migrations",
            isDescShow: false,
          },
          {
            featureType: "Apollo Federation v1 support",
            isDescShow: false,
          },
          {
            featureType: "Import Action from OpenAPI Specification (OAS)",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Data connectors",
        list: [
          {
            featureType: "PostgreSQL",
            isDescShow: false,
          },
          {
            featureType: "Google BigQuery*",
            isDescShow: false,
          },
          {
            featureType: "Microsoft SQL Server*",
            isDescShow: false,
          },
          {
            featureType: "CockroachDB*",
            isDescShow: false,
          },
          {
            featureType: "Google AlloyDB*",
            isDescShow: false,
          },
          {
            featureType: "Citus / Hyperscale*",
            isDescShow: false,
          },
          {
            featureType: "SQLite",
            isDescShow: false,
          },
          {
            featureType: "Amazon Athena",
            isDescShow: false,
          },
          {
            featureType: "Snowflake",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Performance",
        list: [
          {
            featureType: "Query cache",
            isDescShow: false,
            // desc: "Configure caches of frequently executed queries to improve response time",
          },
          {
            featureType: "Read replicas",
            isDescShow: false,
            // desc: "Increase query performance by load balancing queries and subscriptions across replicas",
          },
          {
            featureType: "Dynamic database connection routing",
            isDescShow: false,
          },
          {
            featureType: "Auto cleanup of event triggers",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Security",
        list: [
          {
            featureType: "Role-based authorization ",
            isDescShow: false,
            // desc: "Restrict access based on operations per minute, or deeply nested queries that can be defined by role or IP address",
          },
          {
            featureType: "GraphQL operation allow list",
            isDescShow: false,
            // desc: "Invite team members and grant partial or complete access to your project",
          },
          {
            featureType: "TLS allow list",
            isDescShow: false,
            // desc: "Specify safe operations and only allow certain queries and mutations",
          },
          {
            featureType: "Role-based API depth limit",
            isDescShow: false,
          },
          {
            featureType: "Role-based API node limit",
            isDescShow: false,
          },
          {
            featureType: "Role-based API rate limit",
            isDescShow: false,
          },
          {
            featureType: "Role-based API timeout",
            isDescShow: false,
          },
          {
            featureType: "Disable GraphQL introspection",
            isDescShow: false,
          },
          {
            featureType: "Multiple JWT secrets",
            isDescShow: false,
          },
          {
            featureType: "Multiple admin keys",
            isDescShow: false,
          },
          {
            featureType: "Collaborator management",
            isDescShow: false,
          },
          {
            featureType: "SSO (SAML)",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Observability",
        list: [
          {
            featureType: "Observability data (logs, metrics, traces)",
            isDescShow: false,
          },
          {
            featureType:
              "Observability integration (such as Datadog, New Relic, OpenTelemetry, etc)",
            isDescShow: false,
          },
          {
            featureType: "Metrics API access",
            isDescShow: false,
          },
        ],
      },
      {
        listTitle: "Support",
        list: [
          {
            featureType: "Community support",
            isDescShow: false,
          },
          {
            featureType: "Email/ticketed support",
            isDescShow: false,
          },
          {
            featureType: "Priority response SLAs",
            isDescShow: false,
          },
          {
            featureType: "Production support",
            isDescShow: false,
          },
          {
            featureType: "Expert Hasura technical support",
            isDescShow: false,
          },
          {
            featureType: "Onboarding program",
            isDescShow: false,
          },
          {
            featureType: "Named customer success manager - Available",
            isDescShow: false,
          },
        ],
      },
    ],
  },
];

const legacystandardState = [
  {
    title: "Legacy Free",
    featureList: [
      {
        listTitle: "DATA APIS WITH AUTHORIZATION",
        list: [
          {
            featureType: "GraphQL APIs for instant CRUD",
          },
          {
            featureType: "GraphQL subscriptions",
          },
          {
            featureType: "Support for REST endpoints",
          },
          {
            featureType: "Role based Authorization",
          },
          {
            featureType: "Eventing System (Actions and Triggers)",
          },
          {
            featureType: "Admin console",
          },
          {
            featureType: "CLI",
          },
          {
            featureType: "Remote Schemas with Authorization",
          },
          {
            featureType: "Remote Joins",
          },
          {
            featureType: "Metadata APIs",
          },
          {
            featureType: "Schema Migrations",
          },
        ],
      },
      {
        listTitle: "INFRASTRUCTURE",
        list: [
          {
            featureType: "Data passthrough - Up to 1GB per month",
          },
          {
            featureType: "Request volume - 60 requests/minute",
          },
          {
            featureType: "Operation time limit - 60s",
          },
          {
            featureType: "Region availability - Northern California",
          },
          {
            featureType: "Fully managed",
          },
        ],
      },
      {
        listTitle: "RELIABILITY",
        list: [
          {
            featureType: "Analytics - 1 day analytics",
          },
          {
            featureType: "Tracing and observability",
          },
          {
            featureType: "Regression testing",
          },
        ],
      },
      {
        listTitle: "PERFORMANCE",
        list: [
          {
            featureType: "Caching - 1 MB cache",
          },
          {
            featureType: "Read replicas - Up to 10 replicas",
          },
        ],
      },
      {
        listTitle: "SECURITY",
        list: [
          {
            featureType: "API limiting",
          },
          {
            featureType: "Collaborator management - Up to 10 collaborators",
          },
        ],
      },
      {
        listTitle: "CI/CD",
        list: [
          {
            featureType: "GitHub Integration",
          },
          {
            featureType: "Preview Apps",
          },
        ],
      },
      {
        listTitle: "SUPPORT",
        list: [
          {
            featureType: "Community Forum",
          },
          {
            featureType: "Account and SRE Support",
          },
        ],
      },
      {
        listTitle: "COMPLIANCE",
        list: [
          {
            featureType: "SOC2 Type II",
          },
        ],
      },
    ],
  },
  {
    title: "Legacy Standard",
    featureList: [
      {
        listTitle: "DATA APIS WITH AUTHORIZATION",
        list: [
          {
            featureType: "GraphQL APIs for instant CRUD",
          },
          {
            featureType: "GraphQL subscriptions",
          },
          {
            featureType: "Support for REST endpoints",
          },
          {
            featureType: "Role based Authorization",
          },
          {
            featureType: "Eventing System (Actions and Triggers)",
          },
          {
            featureType: "Admin console",
          },
          {
            featureType: "CLI",
          },
          {
            featureType: "Remote Schemas with Authorization",
          },
          {
            featureType: "Remote Joins",
          },
          {
            featureType: "Metadata APIs",
          },
          {
            featureType: "Schema Migrations",
          },
        ],
      },
      {
        listTitle: "INFRASTRUCTURE",
        list: [
          {
            featureType: "Data passthrough - Up to 20 GB ($2 per additional GB)",
          },
          {
            featureType: "Request volume - Unlimited",
          },
          {
            featureType: "Operation time limit - 60s",
          },
          {
            featureType: "Region availability - 12 (AWS) 5 ( Google Cloud)",
          },
          {
            featureType: "Fully managed",
          },
          {
            featureType: "Auto scaling",
          },
          {
            featureType: "High availability",
          },
          {
            featureType: "API access - metrics",
          },
          {
            featureType: "Uptime guarantee - 99.90%",
          },
        ],
      },
      {
        listTitle: "RELIABILITY",
        list: [
          {
            featureType: "Analytics - 1 day analytics",
          },
          {
            featureType: "Tracing and observability",
          },
          {
            featureType: "Regression testing",
          },
        ],
      },
      {
        listTitle: "PERFORMANCE",
        list: [
          {
            featureType: "Caching - 100 MB cache",
          },
          {
            featureType: "Read replicas - Up to 10 replicas",
          },
        ],
      },
      {
        listTitle: "SECURITY",
        list: [
          {
            featureType: "API limiting",
          },
          {
            featureType: "Collaborator management - Up to 10 collaborators",
          },
        ],
      },
      {
        listTitle: "CI/CD",
        list: [
          {
            featureType: "GitHub Integration",
          },
          {
            featureType: "Preview Apps",
          },
        ],
      },
      {
        listTitle: "SUPPORT",
        list: [
          {
            featureType: "Community Forum",
          },
          {
            featureType: "Account and SRE Support",
          },
          {
            featureType: "Dev Support",
          },
        ],
      },
      {
        listTitle: "COMPLIANCE",
        list: [
          {
            featureType: "SOC2 Type II",
          },
        ],
      },
    ],
  },
];

export { mobileCloudState, mobileOnPremState, legacystandardState };
