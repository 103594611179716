import React from "react";
import styled, { css } from "styled-components";

import { Flex, Image, Heading, Text } from "../../globals/UIKit";
import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import { mq } from "../../globals/utils";

export const StyledSectionWrapperLightGray = styled(StyledSectionWrapper)`
  background: linear-gradient(0deg, #f4faff 11.83%, #cfe8ff 100%);

  .pricingPB-300 {
    padding-bottom: 256px;
  }

  ${mq.below.md} {
    .pricingPB-300 {
      padding-bottom: 290px;
    }
  }
`;

export const StyledTopBannerWrapper = styled.div`
  margin: -25px auto 0;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  .subtext {
    width: 60%;
  }
  ${mq.below.lg} {
    padding-bottom: 40px;
  }
  ${mq.below.sm} {
    .mobileAlign {
      text-align: left;
    }
    .subtext {
      width: 100%;
      text-align: left;
    }
  }
  ${mq.below.xs} {
    .mobileAlign {
      text-align: left;
    }
    .subtext {
      width: 100%;
      text-align: left;
    }
  }
`;

const TopBanner = () => {
  return (
    <StyledSectionWrapperLightGray>
      <StyledContainerWrapper>
        <StyledTopBannerWrapper>
          <Flex
            bg="#fff"
            borderRadius="16px"
            justifyContent="center"
            boxShadow="0px 12px 16px -4px rgba(0, 64, 224, 0.29), 0px 4px 6px -2px rgba(0, 58, 204, 0.03)"
            width="72px"
            height="72px"
            mb="24px"
            css={css`
              @media (max-width: 780px) {
                margin-top: 60px;
              }
            `}
          >
            <Image
              src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675271367/main-web/icons/Group_10857_vdcjbe.png"
              alt="hasura-pricing"
              minWidth="32.84px"
              width="32.84px"
            />
          </Flex>
          <Heading
            mb="12px"
            fontSize="30px"
            fontWeight="700"
            color="#1F2A37"
            lineHeight="1.2"
            letterSpacing="-0.01em"
          >
            Hasura Pricing
          </Heading>
          <Text
            fontSize="24px"
            fontWeight="400"
            color="#111927"
            lineHeight="1.5"
            letterSpacing="-0.01em"
            width="65%"
            css={css`
              @media (max-width: 960px) {
                width: 85%;
              }
            `}
          >
            Generous free plans and flexible usage-based pricing that scale with your needs – from
            early exploration to enterprise scale
          </Text>
        </StyledTopBannerWrapper>
        <div className="pricingPB-300"></div>
      </StyledContainerWrapper>
    </StyledSectionWrapperLightGray>
  );
};

export default TopBanner;
