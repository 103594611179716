import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { Flex, Text, Image } from "../../globals/UIKit";
import help from "./images/help.svg";
import { COLORS } from "../../globals/designSystem";
import { linkCardHoverStyles } from "../shared/CommonStyled";
import StyledButton from "../shared/StyledButton";
import { StyledDesc3 } from "../shared/StyledTypography";
import { btnWidth100 } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";
import { HASURA_DOCS_LINK } from "../../utils/constants";

const StyledIndivCardWrapper = styled.div`
  background-color: ${COLORS.white};
  border-radius: 8px;
  min-height: 725px;
  height: auto;
  position: relative;
  width: 100%;
  display: grid;

  border-radius: 16px;
  padding: 4px;
  box-shadow: 0px 12px 16px -4px rgba(28, 38, 63, 0.08), 0px 4px 6px -2px rgba(28, 38, 63, 0.03);

  /* ${linkCardHoverStyles} */

  .featureHeader {
    height: 204px;

    /* background-color: ${COLORS.grey_6}; */
    background: ${props =>
      props.title === "Free"
        ? "radial-gradient(111.03% 111.03% at 44.9% -26.47%, #5c94c8 0%, #004481 100%)"
        : props.title === "Professional"
        ? "radial-gradient(143.14% 143.14% at 50% -50%, #476ED0 0%, #051B55 100%)"
        : props.title === "Enterprise"
        ? "radial-gradient(152.94% 152.94% at 53.32% -19.61%, #2E3E65 0%, #000000 100%);"
        : props.title === "Community"
        ? "radial-gradient(104.9% 104.9% at 54.08% -4.9%, #1EB4D4 0%, #005C8F 100%)"
        : ""};

    border-radius: 16px 16px 0 0;
    padding: 26px 36px;
    padding-bottom: 12px;

    z-index: 1;
    .displayFlex {
      display: flex;
      align-items: center;
      padding-bottom: 12px;
      img {
        margin-right: 20px;
      }
    }
  }

  ul {
    padding: 32px 36px 12px;

    li {
      margin-bottom: 16px;

      padding: 5px 0;

      img {
        margin-top: 2px;
        min-width: 20px;
        width: 20px;
      }
    }
  }

  .buttonWrapper {
    align-self: flex-end;
    padding: 32px 36px;
    padding-top: 0;
  }

  ${mq.between("md", "lg")} {
    .featureHeader {
      .displayFlex {
        img {
          width: 45px;
          margin-right: 12px;
        }
      }
    }
    .featureHeader,
    ul,
    .buttonWrapper {
      padding: 16px;
    }
  }
`;

const IndivFeatureList = ({ features }) => {
  return (
    <StyledIndivCardWrapper title={features?.title}>
      <div>
        <Flex className="featureHeader" flexDirection="column" alignItems="flex-start">
          {/* Plan Name */}
          <Flex
            bg="rgba(210, 214, 219, 0.3)"
            minWidth="91px"
            width="auto"
            height="28px"
            borderRadius="24px"
            justifyContent="center"
            px="16px"
            mb="16px"
          >
            <Image src={features.imgSrc} alt="pricing-icon" width="17px" mr="8px" />
            <Text
              fontSize="14px"
              fontWeight="700"
              lineHeight="1.5"
              color="#fff"
              letterSpacing="0.005em"
            >
              {features.title}
            </Text>
          </Flex>
          <Text
            mb="12px"
            fontSize="24px"
            fontWeight="700"
            lineHeight="1.33"
            color="#F3F4F6"
            letterSpacing="-0.01em"
          >
            {features.type}
          </Text>
          <Text fontSize="16px" fontWeight="500" lineHeight="1.5" color="#F3F4F6">
            {features.desc}
          </Text>
        </Flex>
        {/* Features List */}
        <div>
          {features.list && (
            <ul>
              <Text
                color="#1f2a37"
                fontSize="16px"
                fontWeight="700"
                lineHeight="1.5"
                letterSpacing="0.005em"
                mb="16px"
              >
                {features.listHeading || "Usage limits"}
              </Text>
              {features.list.map((list, index) => {
                return (
                  <li key={index}>
                    <Flex alignItems="flex-start">
                      <Image
                        src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675175990/main-web/icons/Group_11380_lsz7yj.svg"
                        alt="Tick"
                        minWidth="20px"
                        mr="12px"
                        width="20px"
                      />
                      <Text
                        color="#1f2a37"
                        fontSize="14px"
                        fontWeight="400"
                        lineHeight="1.4"
                        letterSpacing="0.005em"
                      >
                        {list}
                        {features.hoverInfo && <img className="rightIcon" src={help} alt="Tick" />}
                      </Text>
                    </Flex>
                  </li>
                );
              })}
            </ul>
          )}
          {/* Secondary list */}
          {features?.secondaryHeading && (
            <>
              <ul style={{ paddingTop: "0px" }}>
                <Text
                  color="#1f2a37"
                  fontSize="16px"
                  fontWeight="700"
                  lineHeight="1.5"
                  letterSpacing="0.005em"
                  mb="16px"
                >
                  {features.secondaryHeading}
                </Text>
                {features.extraList.map((list, index) => {
                  return (
                    <li key={index}>
                      <Flex alignItems="flex-start">
                        <Image
                          src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675175990/main-web/icons/Group_11380_lsz7yj.svg"
                          alt="Tick"
                          minWidth="20px"
                          mr="12px"
                          width="20px"
                        />
                        <Text
                          color="#1f2a37"
                          fontSize="14px"
                          fontWeight="400"
                          lineHeight="1.4"
                          letterSpacing="0.005em"
                        >
                          {list}
                          {features.hoverInfo && (
                            <img className="rightIcon" src={help} alt="Tick" />
                          )}
                        </Text>
                      </Flex>
                    </li>
                  );
                })}
              </ul>
            </>
          )}
        </div>
      </div>
      <div className="buttonWrapper">
        {features.extraGb && (
          <StyledDesc3 variant="grey_70" paddingBottom="pb24">
            {features.extraGb}
          </StyledDesc3>
        )}
        {features.ossLink && (
          <a href={HASURA_DOCS_LINK}>
            <div className="commonLink">{features.ossLink}</div>
          </a>
        )}
        {features.internalLink ? (
          <Link to={features.btnLink}>
            <StyledButton
              variant={features.btnVariant}
              btnWidth="width100"
              css={btnWidth100}
              fontWeight="500"
              raduis="radius8"
            >
              {features.btnText}
            </StyledButton>
          </Link>
        ) : (
          <a href={features.btnLink}>
            <StyledButton
              variant={features.btnVariant}
              btnWidth="width100"
              css={btnWidth100}
              fontWeight="500"
              raduis="radius8"
            >
              {features.btnText}
            </StyledButton>
          </a>
        )}
      </div>
    </StyledIndivCardWrapper>
  );
};

export default IndivFeatureList;
