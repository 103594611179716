import React, { Fragment } from "react";
import styled, { css } from "styled-components";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";

import { StyledTableWrapper } from "../shared/StyledTableWrapper";
import { Heading, Flex, Text, Image } from "../../globals/UIKit";
import {
  // StyledTitle4,
  StyledTableTitle,
  StyledDesc2,
  StyledDesc3,
} from "../shared/StyledTypography";
import { removePadd } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

import help from "./images/help.svg";

const StyledFeatureTableWrapper = styled.div`
  .wd40 {
    width: 40%;
    margin: 0 0;
  }
  .displayFlex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const StyledTableWrapperSelfHost = styled(StyledTableWrapper)`
  thead {
    tr {
      th {
        width: 30%;
        &:first-child {
          width: 40%;
        }
      }
    }
  }
`;

const StyledSectionWrapperHideMobile = styled(StyledSectionWrapper)`
  ${mq.below.lg} {
    display: none;
  }
`;

const DashIcon = () => (
  <Image
    src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675200010/main-web/icons/dash_icon_wh3ir2.svg"
    alt="dash-icon"
    minWidth="14px"
    width="14px"
  />
);

const featuresListState = [
  {
    title: "DATA APIS WITH AUTHORIZATION",
    list: [
      {
        featureType: "GraphQL APIs for instant CRUD",
        legacyFree: "Yes",
        legacyStandard: "Yes",
      },
      {
        featureType: "GraphQL subscriptions",
        legacyFree: "Yes",
        legacyStandard: "Yes",
      },
      {
        featureType: "Support for REST endpoints",
        legacyFree: "Yes",
        legacyStandard: "Yes",
      },
      {
        featureType: "Role based Authorization",
        legacyFree: "Yes",
        legacyStandard: "Yes",
      },
      {
        featureType: "Eventing System (Actions and Triggers)",
        legacyFree: "Yes",
        legacyStandard: "Yes",
      },
      {
        featureType: "Admin console",
        legacyFree: "Yes",
        legacyStandard: "Yes",
      },
      {
        featureType: "CLI",
        legacyFree: "Yes",
        legacyStandard: "Yes",
      },
      {
        featureType: "Remote Schemas with Authorization",
        legacyFree: "Yes",
        legacyStandard: "Yes",
      },
      {
        featureType: "Remote Joins",
        legacyFree: "Yes",
        legacyStandard: "Yes",
      },
      {
        featureType: "Metadata APIs",
        legacyFree: "Yes",
        legacyStandard: "Yes",
      },
      {
        featureType: "Schema Migrations",
        legacyFree: "Yes",
        legacyStandard: "Yes",
      },
    ],
  },
  {
    title: "INFRASTRUCTURE",
    list: [
      {
        featureType: "Data passthrough",
        legacyFree: "Up to 1GB per month",
        legacyStandard: "Up to 20 GB ($2 per additional GB)",
      },
      {
        featureType: "Request volume",
        legacyFree: "60 requests/minute",
        legacyStandard: "Unlimited",
      },
      {
        featureType: "Operation time limit",
        legacyFree: "60s",
        legacyStandard: "60s",
      },
      {
        featureType: "Region availability",
        legacyFree: "Northern California",
        legacyStandard: "12 (AWS) 5 ( Google Cloud)",
      },
      {
        featureType: "Fully managed",
        legacyFree: "Yes",
        legacyStandard: "Yes",
      },
      {
        featureType: "Auto scaling",
        legacyFree: <DashIcon />,
        legacyStandard: "Yes",
      },
      {
        featureType: "High availability",
        legacyFree: <DashIcon />,
        legacyStandard: "Yes",
      },
      {
        featureType: "API access - metrics",
        legacyFree: <DashIcon />,
        legacyStandard: "Yes",
      },
      {
        featureType: "Uptime guarantee",
        legacyFree: <DashIcon />,
        legacyStandard: "99.90%",
      },
    ],
  },
  {
    title: "RELIABILITY",
    list: [
      {
        featureType: "Analytics",
        legacyFree: "Yes (1 day analytics)",
        legacyStandard: "Yes (1 day analytics)",
      },
      {
        featureType: "Tracing and observability",
        legacyFree: "Yes",
        legacyStandard: "Yes",
      },
      {
        featureType: "Regression testing",
        legacyFree: "Yes",
        legacyStandard: "Yes",
      },
    ],
  },
  {
    title: "PERFORMANCE",
    list: [
      {
        featureType: "Caching",
        legacyFree: "Yes (1 MB cache)",
        legacyStandard: "Yes (100 MB cache)",
      },
      {
        featureType: "Read replicas",
        legacyFree: "Yes (Up to 10 replicas)",
        legacyStandard: "Yes (Up to 10 replicas)",
      },
    ],
  },
  {
    title: "SECURITY",
    list: [
      {
        featureType: "API limiting",
        legacyFree: "Yes",
        legacyStandard: "Yes",
      },
      {
        featureType: "Collaborator management",
        legacyFree: "Yes (Up to 10 collaborators)",
        legacyStandard: "Yes (Up to 10 collaborators)",
      },
      {
        featureType: "Allow-listing",
        legacyFree: <DashIcon />,
        legacyStandard: <DashIcon />,
      },
      {
        featureType: "VPC PrivateLink",
        legacyFree: <DashIcon />,
        legacyStandard: <DashIcon />,
      },
      {
        featureType: "Dedicated VPC with VPC Peering",
        legacyFree: <DashIcon />,
        legacyStandard: <DashIcon />,
      },
    ],
  },
  {
    title: "INTEGRATIONS",
    list: [
      {
        featureType: "SSO",
        legacyFree: <DashIcon />,
        legacyStandard: <DashIcon />,
      },
      {
        featureType: "Alerting (eg: Pagerduty)",
        legacyFree: <DashIcon />,
        legacyStandard: <DashIcon />,
      },
      {
        featureType: "APM (eg: Datadog, New Relic)",
        legacyFree: <DashIcon />,
        legacyStandard: <DashIcon />,
      },
    ],
  },
  {
    title: "CI/CD",
    list: [
      {
        featureType: "GitHub Integration",
        legacyFree: "Yes",
        legacyStandard: "Yes",
      },
      {
        featureType: "Preview Apps",
        legacyFree: "Yes",
        legacyStandard: "Yes",
      },
    ],
  },
  {
    title: "SUPPORT",
    list: [
      {
        featureType: "Community Forum",
        legacyFree: "Yes",
        legacyStandard: "Yes",
      },
      {
        featureType: "Account and SRE Support",
        legacyFree: "Yes",
        legacyStandard: "Yes",
      },
      {
        featureType: "Dev Support",
        legacyFree: <DashIcon />,
        legacyStandard: "Yes",
      },
      {
        featureType: "Custom SLAs",
        legacyFree: <DashIcon />,
        legacyStandard: <DashIcon />,
      },
      {
        featureType: "Dedicated Account Manager",
        legacyFree: <DashIcon />,
        legacyStandard: <DashIcon />,
      },
      {
        featureType: "Consulting, Solutioning and Training",
        legacyFree: <DashIcon />,
        legacyStandard: <DashIcon />,
      },
    ],
  },
  {
    title: "COMPLIANCE",
    list: [
      {
        featureType: "Business Associate Agreement",
        legacyFree: <DashIcon />,
        legacyStandard: <DashIcon />,
      },
      {
        featureType: "Data Processing Agreement (GDPR)",
        legacyFree: <DashIcon />,
        legacyStandard: <DashIcon />,
      },
      {
        featureType: "HIPAA compliance",
        legacyFree: <DashIcon />,
        legacyStandard: <DashIcon />,
      },
      {
        featureType: "SOC2 Type II",
        legacyFree: "Yes",
        legacyStandard: "Yes",
      },
    ],
  },
  {
    title: "BILLING",
    list: [
      {
        featureType: "Annual billing",
        legacyFree: <DashIcon />,
        legacyStandard: <DashIcon />,
      },
    ],
  },
];

const TableHeader = ({ tableFooter }) => {
  return (
    <>
      {tableFooter ? (
        <tr>
        </tr>
      ) : (
        <thead>
          <tr>
            <th scope="col" className={tableFooter ? "tableFooter" : ""}>
              <Flex flexDirection="column" alignItems="flex-start" pb="25px">
                <Heading
                  as="h2"
                  fontWeight="700"
                  color="#111927"
                  fontSize="36px"
                  lineHeight="1.2"
                  letterSpacing="-0.015em"
                >
                  Features
                </Heading>
              </Flex>
            </th>
            <th scope="col" className={tableFooter ? "tableFooter" : ""}>
              <Flex
                flexDirection="column"
                alignItems="flex-start"
                pr="32px"
                pb="0px"
                css={css`
                  button {
                    min-width: 216px;
                  }
                `}
              >
                <Heading
                  as="h2"
                  fontWeight="700"
                  color="#111927"
                  fontSize="18px"
                  lineHeight="1.5"
                  letterSpacing="-0.01em"
                  mb="4px"
                >
                  Legacy Free
                </Heading>
                <Text
                  fontSize="16px"
                  fontWeight="700"
                  lineHeight="1.5"
                  color="#111927"
                  letterSpacing="0.005em"
                  mb="12px"
                >
                  (Free)
                </Text>
              </Flex>
            </th>
            <th scope="col" className={tableFooter ? "tableFooter" : ""}>
              <Flex
                flexDirection="column"
                alignItems="flex-start"
                pr="32px"
                pb="0px"
                css={css`
                  button {
                    min-width: 216px;
                  }
                `}
              >
                <Heading
                  as="h2"
                  fontWeight="700"
                  color="#111927"
                  fontSize="18px"
                  lineHeight="1.5"
                  letterSpacing="-0.01em"
                  mb="4px"
                >
                  Legacy Standard
                </Heading>
                <Text
                  fontSize="16px"
                  fontWeight="700"
                  lineHeight="1.5"
                  color="#111927"
                  letterSpacing="0.005em"
                  mb="12px"
                >
                  ($99/month)
                </Text>
              </Flex>
            </th>
          </tr>
        </thead>
      )}
    </>
  );
};

const LegacyStandardTableList = () => {
  const featuresList = featuresListState.map((features, index) => {
    const featuesListLength = features.list?.length;
    return (
      <Fragment key={index}>
        {features.title ? (
          <tr>
            <td colSpan="3" className="tableTitle top92">
              <StyledTableTitle css={removePadd}>{features.title}</StyledTableTitle>
            </td>
          </tr>
        ) : null}

        {features.list.map((core, subIndex) => {
          let doublePaddClass = "";
          if (subIndex === 0) {
            doublePaddClass = "doublePaddTopRow";
          }

          if (subIndex === featuesListLength - 1) {
            doublePaddClass += " doublePaddBottomRow";
          }

          const isLastRow = subIndex === featuesListLength - 1;

          return (
            <tr key={subIndex} className={doublePaddClass}>
              <td>
                <StyledDesc2 className={isLastRow ? "mlt16" : "mlt16 border-bottom"}>
                  {core.featureType}
                  {core.desc ? (
                    <span className="toolTipWrapper">
                      <img className="toolTip rightIcon" src={help} alt="help" />
                      {core.desc ? (
                        <div className="toolTipText">
                          <StyledDesc3 variant="grey_100">{core.desc}</StyledDesc3>
                        </div>
                      ) : null}
                    </span>
                  ) : null}
                </StyledDesc2>
              </td>
              <td align="left">
                <StyledDesc2
                  textAlign="left"
                  className={isLastRow ? "mlt16" : "mlt16 border-bottom"}
                >
                  {core.legacyFree ? core.legacyFree : ""}
                </StyledDesc2>
              </td>
              <td align="left">
                <StyledDesc2
                  textAlign="left"
                  className={isLastRow ? "mlt16" : "mlt16 border-bottom"}
                >
                  {core.legacyStandard ? core.legacyStandard : ""}
                </StyledDesc2>
              </td>
            </tr>
          );
        })}
      </Fragment>
    );
  });

  return (
    <StyledSectionWrapperHideMobile>
      <StyledFeatureTableWrapper>
        <StyledTableWrapperSelfHost id="self-feature-table" isPricingPage>
          <TableHeader />
          <tbody className="tableShadow">
            {featuresList}
            <TableHeader tableFooter={true} />
          </tbody>
        </StyledTableWrapperSelfHost>
      </StyledFeatureTableWrapper>
    </StyledSectionWrapperHideMobile>
  );
};

export default LegacyStandardTableList;
