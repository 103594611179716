import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { Link } from "gatsby";

import { Heading, Flex, Image, Text } from "../../globals/UIKit";
import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import { StyledTableWrapper } from "../shared/StyledTableWrapper";
import StyledButton from "../shared/StyledButton";
import { StyledTableTitle, StyledDesc3, StyledDesc2 } from "../shared/StyledTypography";
import { removePadd } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";
import help from "./images/help.svg";

const StyledSectionWrapperHideMobile = styled(StyledSectionWrapper)`
  ${mq.below.lg} {
    display: none;
  }
`;

const StyledFeatureTableWrapper = styled.div`
  .wd40 {
    width: 40%;
    margin: 0 0;
  }
  .displayFlex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const RightTick = () => (
  <Image
    src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675197052/main-web/icons/Icon_h9rnq3.svg"
    alt="Tick"
    minWidth="16px"
    width="16px"
  />
);

const DashIcon = () => (
  <Image
    src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675200010/main-web/icons/dash_icon_wh3ir2.svg"
    alt="dash-icon"
    minWidth="14px"
    width="14px"
    mt="-2px"
  />
);

const featuresListState = [
  {
    title: "Data APIs",
    list: [
      {
        featureType: "Instant GraphQL APIs for CRUD operations",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "RESTified endpoints",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "GraphQL subscriptions",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Remote Schemas",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
        // desc: "Define granular role-based access control rules for every field in your GraphQL schema, and to any row or column in your database",
      },
      {
        featureType: "Remote joins",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
        // desc: "Invoke custom logic using webhooks with database triggers",
      },
      {
        featureType: "Actions",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
        // desc: "Easily connect and configure your data sources with an intuitive GUI",
      },
      {
        featureType: "Event triggers & scheduled triggers",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
        // desc: "Easily connect and configure your data sources with an intuitive GUI",
      },
      {
        featureType: "Admin console",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
        // desc: "Command line tool to manage your Hasura projects and migrations",
      },
      {
        featureType: "GraphiQL query and mutation designer",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
        // desc: "Add external GraphQL APIs and access with with Hasura's built in authorization",
      },
      {
        featureType: "CLI",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
        // desc: "Join data from disparate data sources such as databases and other remote data sources",
      },
      {
        featureType: "Metadata APIs",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
        // desc: "Admin API to manage the Hasura schema and metadata.",
      },
      {
        featureType: "Schema Migrations",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Apollo Federation v1 support",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Import Action from OpenAPI Specification (OAS)",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Connected databases per project",
        hasuraCloudFree: "Up to 2",
        hasuraCloudStandard: "No limit",
        hasuraCloudEnterprise: "No limit",
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Projects per account",
        hasuraCloudFree: "Up to 3",
        hasuraCloudStandard: "No limit",
        hasuraCloudEnterprise: "No limit",
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Data passthrough ",
        hasuraCloudFree: "Up to 100 MB / month",
        hasuraCloudStandard: "Unlimited at $0.13/GB",
        hasuraCloudEnterprise: "Unlimited at $0.13/GB",
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "API request count (includes websocket messages)",
        hasuraCloudFree: "Up to 3 million / month",
        hasuraCloudStandard: "Up to 6 million / month",
        hasuraCloudEnterprise: "No limit",
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      // {
      //   featureType: "API rate limit",
      //   hasuraCloudFree: "Up to 60 reqs / minute",
      //   hasuraCloudStandard: "Up to 120 reqs / minute",
      //   hasuraCloudEnterprise: "No limit",
      //   hasuraCE: <RightTick />,
      //   hasuraEE: <RightTick />,
      //   isDescShow: false,
      // },
      {
        featureType: "API request duration",
        hasuraCloudFree: "Up to 60 seconds",
        hasuraCloudStandard: "Up to 120 seconds",
        hasuraCloudEnterprise: "No limit",
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Concurrent websocket connections",
        hasuraCloudFree: "Up to 10",
        hasuraCloudStandard: "Up to 100",
        hasuraCloudEnterprise: "No limit",
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Database connection pool idle timeout",
        hasuraCloudFree: "Preset by Hasura",
        hasuraCloudStandard: "User-configured",
        hasuraCloudEnterprise: "User-configured",
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Database connection pool size",
        hasuraCloudFree: "Preset by Hasura",
        hasuraCloudStandard: "User-configured",
        hasuraCloudEnterprise: "User-configured",
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "DB connection pool minimum number of connections to keep-alive",
        hasuraCloudFree: "Preset by Hasura",
        hasuraCloudStandard: "User-configured",
        hasuraCloudEnterprise: "User-configured",
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
    ],
  },
  {
    title: "Data connectors",
    list: [
      {
        featureType: "PostgreSQL",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Snowflake",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Google BigQuery",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Amazon Athena ",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Microsoft SQL Server",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "CockroachDB",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Google AlloyDB",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Citus / Hyperscale",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "SQLite",
        hasuraCloudFree: (
          <span>
            <RightTick />
            <Text as="span" ml="12px">
              (self-hosted)
            </Text>
          </span>
        ),
        hasuraCloudStandard: (
          <span>
            <RightTick />
            <Text as="span" ml="12px">
              (self-hosted)
            </Text>
          </span>
        ),
        hasuraCloudEnterprise: (
          <span>
            <RightTick />
            <Text as="span" ml="12px">
              (self-hosted)
            </Text>
          </span>
        ),
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
    ],
  },
  {
    title: "Performance",
    list: [
      {
        featureType: "Query cache",
        hasuraCloudFree: (
          <span>
            <RightTick />
            <Text as="span" ml="12px">
              (max 100 MB)
            </Text>
          </span>
        ),
        hasuraCloudStandard: (
          <span>
            <RightTick />
            <Text as="span" ml="12px">
              (max 1 GB)
            </Text>
          </span>
        ),
        hasuraCloudEnterprise: (
          <span>
            <RightTick />
            <Text as="span" ml="12px">
              (no limit)
            </Text>
          </span>
        ),
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Read replicas",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: (
          <span>
            <RightTick />
            <Text as="span" ml="12px">
              (max 3)
            </Text>
          </span>
        ),
        hasuraCloudEnterprise: (
          <span>
            <RightTick />
            <Text as="span" ml="12px">
              (no limit)
            </Text>
          </span>
        ),
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Dynamic database connection routing",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: (
          <span>
            <RightTick />
            <Text as="span" ml="12px">
              (max 1)
            </Text>
          </span>
        ),
        hasuraCloudEnterprise: (
          <span>
            <RightTick />
            <Text as="span" ml="12px">
              (no limit)
            </Text>
          </span>
        ),
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Auto cleanup of event triggers",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <DashIcon />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
    ],
  },
  {
    title: "Security",
    list: [
      {
        featureType: "Role-based authorization",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Collaborator management",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "GraphQL operations allow list",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "TLS allow list",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Role-based API depth limit",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Role-based API node limit",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Role-based API rate limit",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: "User-configured",
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Role-based API timeout",
        hasuraCloudFree: "Up to 60 secs",
        hasuraCloudStandard: "Up to 120 secs",
        hasuraCloudEnterprise: "Configurable limit",
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Disable GraphQL introspection",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Multiple JWT secrets",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Multiple admin keys",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "SSO (SAML)",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <DashIcon />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
    ],
  },
  {
    title: "Observability",
    list: [
      {
        featureType: "Metrics, logs, and traces",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType:
          "Observability integration (such as Datadog, New Relic, Azure Monitor, Prometheus and OpenTelemetry)",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Metrics API access",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
    ],
  },
  {
    title: "Infrastructure",
    list: [
      {
        featureType: "Cloud providers: AWS, Google Cloud",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Region availability",
        hasuraCloudFree: (
          <span>
            12 (AWS)
            <br />5 (Google Cloud)
          </span>
        ),
        hasuraCloudStandard: (
          <span>
            12 (AWS)
            <br />5 (Google Cloud)
          </span>
        ),
        hasuraCloudEnterprise: (
          <span>
            12 (AWS)
            <br />5 (Google Cloud)
            <br />
            Additional regions available on request.
          </span>
        ),
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Custom domain (DNS CNAME)",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Elastic connection pooling",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Deployment autoscaling",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <DashIcon />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Dedicated cluster",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <DashIcon />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Uptime SLA",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: "99.90%",
        hasuraCloudEnterprise: "99.95%",
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Failover for high availability",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <DashIcon />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "VPC peering",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <DashIcon />,
        hasuraCloudEnterprise: "1 included ($250/month per additional VPC)",
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
    ],
  },
  {
    title: "CI/CD integrations",
    list: [
      {
        featureType: "GitHub integration",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Preview apps",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
    ],
  },
  {
    title: "Compliance",
    list: [
      {
        featureType: "SOC 2 Type 2",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Data Processing Agreement (GDPR)",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <DashIcon />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Business Associate Agreement (BAA)",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <DashIcon />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "HIPAA compliance",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <DashIcon />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
    ],
  },
  {
    title: "Support",
    list: [
      {
        featureType: "Community support",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Email/ticketed support",
        hasuraCloudFree: <RightTick />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Expert Hasura technical support",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <RightTick />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Priority response SLAs",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <DashIcon />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Onboarding program",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <DashIcon />,
        hasuraCloudEnterprise: <RightTick />,
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
      {
        featureType: "Named customer success manager",
        hasuraCloudFree: <DashIcon />,
        hasuraCloudStandard: <DashIcon />,
        hasuraCloudEnterprise: "Available",
        hasuraCE: <RightTick />,
        hasuraEE: <RightTick />,
        isDescShow: false,
      },
    ],
  },
];

const TableHeader = ({ tableFooter }) => {
  return (
    <>
      {tableFooter ? (
        <tr>
          {/*eslint-disable-next-line*/}
          <th scope="col" className={tableFooter ? "tableFooter" : ""}></th>
          <th scope="col" className={tableFooter ? "tableFooter" : ""}>
            {tableFooter && (
              <div className="buttonWrapper">
                <a href="https://cloud.hasura.io/signup?pg=pricing&plcmt=body&cta=get-started-for-free&tech=default">
                  <StyledButton
                    variant="price_blue_100"
                    btnWidth="width100"
                    fontWeight="500"
                    size="price_sm"
                    raduis="radius8"
                  >
                    Try free
                  </StyledButton>
                </a>
              </div>
            )}
          </th>
          <th scope="col" className={tableFooter ? "tableFooter borderRadiusBottom" : ""}>
            {tableFooter && (
              <div className="buttonWrapper">
                <a href="https://cloud.hasura.io/signup?pg=pricing&plcmt=body&cta=get-started-for-free&tech=default">
                  <StyledButton
                    variant="price_blue_600"
                    btnWidth="width100"
                    fontWeight="500"
                    size="price_sm"
                    raduis="radius8"
                  >
                    Start on Free tier
                  </StyledButton>
                </a>
              </div>
            )}
          </th>
          <th scope="col" className={tableFooter ? "tableFooter" : ""}>
            {tableFooter && (
              <div className="buttonWrapper">
                <a href="/contact-us/">
                  <StyledButton
                    variant="neutral_400"
                    btnWidth="width100"
                    fontWeight="500"
                    size="price_sm"
                    raduis="radius8"
                  >
                    Contact us
                  </StyledButton>
                </a>
              </div>
            )}
          </th>
        </tr>
      ) : (
        <thead>
          <tr>
            <th scope="col" className={tableFooter ? "tableFooter" : ""}>
              <Flex flexDirection="column" alignItems="flex-start" pb="47px">
                <Heading
                  as="h2"
                  fontWeight="700"
                  color="#111927"
                  fontSize="36px"
                  lineHeight="1.2"
                  letterSpacing="-0.015em"
                >
                  Compare plans
                </Heading>
              </Flex>
            </th>
            <th scope="col" className={tableFooter ? "tableFooter" : ""}>
              <Flex
                flexDirection="column"
                alignItems="flex-start"
                pb="30px"
                css={css`
                  button {
                    min-width: 216px;
                  }
                `}
              >
                <Heading
                  as="h2"
                  fontWeight="700"
                  color="#111927"
                  fontSize="18px"
                  lineHeight="1.5"
                  letterSpacing="-0.01em"
                  mb="30px"
                >
                  Free
                </Heading>
                <a href="https://cloud.hasura.io/signup?pg=pricing&plcmt=body&cta=get-started-for-free&tech=default">
                  <StyledButton
                    variant="price_blue"
                    btnWidth="width100"
                    fontWeight="500"
                    size="price_sm"
                    raduis="radius8"
                  >
                    Try free
                  </StyledButton>
                </a>
              </Flex>
              {tableFooter && (
                <div className="buttonWrapper">
                  <a href="https://cloud.hasura.io/signup?pg=pricing&plcmt=body&cta=get-started-for-free&tech=default">
                    <StyledButton
                      variant="transparent_grey"
                      btnWidth="width100"
                      size="sm"
                      raduis="radius8"
                    >
                      Get Started for Free
                    </StyledButton>
                  </a>
                </div>
              )}
            </th>
            <th scope="col" className={tableFooter ? "tableFooter" : ""}>
              <Flex
                flexDirection="column"
                alignItems="flex-start"
                pb="30px"
                css={css`
                  button {
                    min-width: 216px;
                  }
                `}
              >
                <Heading
                  as="h2"
                  fontWeight="700"
                  color="#111927"
                  fontSize="18px"
                  lineHeight="1.5"
                  letterSpacing="-0.01em"
                  mb="30px"
                >
                  Professional
                </Heading>
                <a href="https://cloud.hasura.io/signup?pg=pricing&plcmt=body&cta=get-started-for-free&tech=default">
                  <StyledButton
                    variant="price_blue"
                    btnWidth="width100"
                    fontWeight="500"
                    size="price_sm"
                    raduis="radius8"
                  >
                    Start on Free tier
                  </StyledButton>
                </a>
              </Flex>
              {tableFooter && (
                <div className="buttonWrapper">
                  <a href="https://cloud.hasura.io/signup?pg=pricing&plcmt=body&cta=start-on-free-tier&tech=default">
                    <StyledButton variant="green" btnWidth="width100" size="sm">
                      Start on Free Tier
                    </StyledButton>
                  </a>
                </div>
              )}
            </th>
            <th scope="col" className={tableFooter ? "tableFooter" : ""}>
              <Flex
                flexDirection="column"
                alignItems="flex-start"
                pb="30px"
                css={css`
                  button {
                    min-width: 216px;
                  }
                `}
              >
                <Heading
                  as="h2"
                  fontWeight="700"
                  color="#111927"
                  fontSize="18px"
                  lineHeight="1.5"
                  letterSpacing="-0.01em"
                  mb="30px"
                >
                  Enterprise
                </Heading>
                <a href="/contact-us/">
                  <StyledButton
                    variant="neutral_400"
                    btnWidth="width100"
                    fontWeight="500"
                    size="price_sm"
                    raduis="radius8"
                  >
                    Contact us
                  </StyledButton>
                </a>
              </Flex>
              {tableFooter && (
                <div className="buttonWrapper">
                  <Link to="/contact-us/?type=hasuraenterprise">
                    <StyledButton variant="transparent_grey" btnWidth="width100" size="sm">
                      Contact Sales
                    </StyledButton>
                  </Link>
                </div>
              )}
            </th>
          </tr>
        </thead>
      )}
    </>
  );
};

const FeaturesTableList = () => {
  const featuresList = featuresListState.map((features, index) => {
    const featuesListLength = features.list?.length;
    return (
      <Fragment key={index}>
        {features.title && (
          <tr>
            <td colSpan="4" className="tableTitle">
              <StyledTableTitle css={removePadd}>{features.title}</StyledTableTitle>
            </td>
          </tr>
        )}

        {features.list.map((core, subIndex) => {
          let doublePaddClass = "";
          if (subIndex === 0) {
            doublePaddClass = "doublePaddTopRow";
          }

          if (subIndex === featuesListLength - 1) {
            doublePaddClass += " doublePaddBottomRow";
          }

          const isLastRow = subIndex === featuesListLength - 1;

          return (
            <tr key={subIndex} className={doublePaddClass}>
              <td>
                <StyledDesc2 className={isLastRow ? "mlt16" : "mlt16 border-bottom"}>
                  {core.featureType}
                  {core.desc && (
                    <span className="toolTipWrapper">
                      <Image className="toolTip rightIcon" src={help} alt="help" />
                      {core.desc && (
                        <div className="toolTipText">
                          <StyledDesc3 variant="grey_100">{core.desc}</StyledDesc3>
                        </div>
                      )}
                    </span>
                  )}
                </StyledDesc2>
              </td>
              <td align="left">
                <StyledDesc2
                  textAlign="left"
                  className={isLastRow ? "mlt16" : "mlt16 border-bottom"}
                >
                  {core.hasuraCloudFree ? core.hasuraCloudFree : ""}
                </StyledDesc2>
              </td>
              <td align="left">
                <StyledDesc2
                  textAlign="left"
                  className={isLastRow ? "mlt16" : "mlt16 border-bottom"}
                >
                  {core.hasuraCloudStandard ? core.hasuraCloudStandard : ""}
                </StyledDesc2>
              </td>
              <td align="left">
                <StyledDesc2
                  textAlign="left"
                  className={isLastRow ? "mlt16" : "mlt16 border-bottom"}
                >
                  {core.hasuraCloudEnterprise ? core.hasuraCloudEnterprise : ""}
                </StyledDesc2>
              </td>
            </tr>
          );
        })}
      </Fragment>
    );
  });

  return (
    <StyledSectionWrapperHideMobile>
      <StyledFeatureTableWrapper>
        <StyledTableWrapper className="highlightsBgColor" id="feature-table" isPricingPage>
          <TableHeader />
          <tbody>
            {featuresList}
            <TableHeader tableFooter={true} />
          </tbody>
        </StyledTableWrapper>
      </StyledFeatureTableWrapper>
    </StyledSectionWrapperHideMobile>
  );
};

export default FeaturesTableList;
