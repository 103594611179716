import styled from "styled-components";
import { COLORS } from "../../globals/designSystem";
import { ELEVATION } from "../../globals/designSystem";

export const StyledTableWrapper = styled.table`
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
  position: relative;
  margin-top: ${props => (props?.isPricingPage ? "35px" : "80px")};

  width: 100%;

  &.highlightsBgColor {
    thead,
    tbody {
      tr {
        th,
        td {
          &:nth-child(3) {
            background-color: #f3f9fe;
            border: 1px solid #f0f4ff;
          }
        }
        th {
          &:nth-child(3) {
            border-radius: 16px 16px 0 0;
          }
        }
      }
    }
  }

  thead {
    tr {
      th {
        background-color: ${COLORS.white};
        padding: 12px 32px;
        width: 24%;
        position: sticky;
        top: 0px;
        z-index: 8;
        img {
          width: 24px;
        }
        &:first-child {
          width: 28%;
        }
      }
      .t65 {
        top: 65px;
      }
    }
  }
  tbody {
    tr {
      &:nth-last-child(2) {
        td {
          .mlt16 {
            border-bottom: 0px !important;
          }
        }
      }
      &.doublePaddTopRow {
        td {
          padding-top: 32px;
        }
      }
      &.doublePaddBottomRow {
        td {
          padding-bottom: 32px;
        }
      }
      td {
        width: 24%;
        text-align: center;
        padding: 12px 32px;
        padding-bottom: 0;
        vertical-align: bottom;
        &:first-child {
          width: 28%;
          text-align: left;
          padding-right: 20px;
          padding-left: 0;
          .mlt16 {
            margin-left: 32px;
          }
        }

        .mlt16 {
          margin: 0 16px;
          padding-bottom: 12px;
        }

        .border-bottom {
          border-bottom: 1px solid #e5e7eb !important;
        }

        .toolTipWrapper {
          position: relative;
          &:hover {
            .toolTipText {
              display: block;
            }
          }
          .toolTip {
            cursor: pointer;
            margin-left: 6px;
          }
          .rightIcon {
            max-width: 20px;
            width: 20px;
          }
          .toolTipText {
            display: none;
            width: 300px;
            border-radius: 8px;
            padding: 16px 24px;
            position: absolute;
            left: -5px;
            bottom: calc(100% + 10px);
            background-color: ${COLORS.white};
            color: ${COLORS.grey_100};
            z-index: 10;
            box-shadow: ${ELEVATION.l_2};
            &:before,
            &:after {
              -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
              clip-path: polygon(50% 100%, 0 0, 100% 0);
              border-top: 1px solid ${COLORS.white};
              content: "";
              position: absolute;
            }
            &:before {
              content: "";
              background-color: ${COLORS.white};
              width: 20px;
              height: 10px;
              left: 14px;
              bottom: -10px;
            }
            &:after {
              content: "";
              background-color: ${COLORS.white};
              width: 16px;
              height: 8px;
              left: 14px;
              bottom: -8px;
            }
          }
        }
      }
      th {
        img {
          width: 24px;
        }
        .buttonWrapper {
          padding-top: 16px;
        }
      }

      .tableTitle {
        padding: 16px 32px;
        padding-left: 32px !important;
        background-color: #edf4fa;
        position: sticky;
        top: 157px;
        z-index: 1;
        border: 1px solid #e5e7eb;
        border-radius: 4px;
        &.top92 {
          top: 92px;
        }
      }

      @supports (background: -webkit-canvas(squares)) {
        .tableTitle {
          top: 0px;
        }
      }
      .tableTitleTop {
        top: 0px;
      }
      .tableFooter {
        padding: 32px 12px;
        padding-top: 0;
        &:first-child {
          padding-left: 0;
        }
        .buttonWrapper {
          padding: 0 16px;
        }
      }
      .borderRadiusBottom {
        border-radius: 0 0 16px 16px !important;
      }
    }
  }
`;

export const StyledSeparator = styled.hr`
  border-top-width: 2px;
  margin: 140px 0 20px;
`;
