import React from "react";
import { css } from "styled-components";

import { Flex, Box, Image, Text } from "../../globals/UIKit";

export const HasuraUsers = () => (
  <Flex
    width="100%"
    justifyContent="center"
    pt="63px"
    mt="75px"
    css={css`
      background-image: url("https://res.cloudinary.com/dh8fp23nd/image/upload/v1675236809/main-web/Group_11462_1_1_wy8ya5.png");
      /* background-size: cover; */
      background-repeat: no-repeat;
      background-position-x: 12em;

      @media (max-width: 1024px) {
        background: #fff;
        padding-top: 20px;
        margin-bottom: 60px;
      }

      @media (max-width: 980px) and (min-width: 781px) {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .second-div {
          padding-top: 0;
        }

        .third-div {
          margin-right: 0;
          padding-bottom: 0;
        }

        .fourth-div {
          margin-top: 28px;
        }
      }

      @media (max-width: 780px) {
        margin-top: 30px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .first-div,
        .second-div,
        .third-div,
        .fourth-div {
          padding: 0;
          margin: 0 0 28px 0;
        }

        .brands-desc {
          margin: 0 auto;
          text-align: center;
          margin-bottom: 20px;
        }
      }
    `}
  >
    {/* Column 1 */}
    <Box mr="28px" className="first-div">
      <Text
        fontSize="12.17px"
        color="#4D5761"
        lineHeight="1.5"
        letterSpacing="0.005em"
        fontWeight="700"
        mb="18px"
        className="brands-desc"
      >
        TRUSTED BY GLOBAL BRANDS
      </Text>
      <Flex
        width="212px"
        height="140px"
        background="linear-gradient(180deg, #0057CC 0%, #013481 100%)"
        borderRadius="12.59px"
        boxShadow="inset 0px -2.43439px 7.82484px 2.08662px rgba(89, 127, 221, 0.69)"
        justifyContent="center"
      >
        <Image
          src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675233484/main-web/icons/image_92_xrse1l.png"
          alt="Atlassian"
          width="125px"
        />
      </Flex>
    </Box>
    {/* Column 2 */}
    <Box mr="28px" pt="37px" className="second-div">
      <Flex
        width="212px"
        height="140px"
        background="linear-gradient(180deg, #FC8019 0%, #AF3E0D 100%)"
        borderRadius="12.59px"
        boxShadow="inset 0px -2.43439px 7.82484px 2.08662px rgba(253, 216, 196, 0.69), 0px 15.6498px 18.7798px -3.12996px rgba(28, 38, 63, 0.08), 0px 6.25993px 6.25993px -3.12996px rgba(28, 38, 63, 0.03)"
        justifyContent="center"
        mb="28px"
      >
        <Image
          src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675233484/main-web/icons/image_91_endgdn.png"
          alt="Swiggy"
          width="113px"
        />
      </Flex>
      <Flex
        width="212px"
        height="140px"
        background="linear-gradient(180deg, #00205B 0%, #001437 100%)"
        borderRadius="12.59px"
        boxShadow="inset 0px -2.43439px 7.82484px 2.08662px rgba(190, 209, 255, 0.69), 0px 15.6498px 18.7798px -3.12996px rgba(28, 38, 63, 0.08), 0px 6.25993px 6.25993px -3.12996px rgba(28, 38, 63, 0.03)"
        justifyContent="center"
      >
        <Image
          src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675234990/main-web/icons/image_93_pb3r47.png"
          alt="Airbus"
          width="101px"
        />
      </Flex>
    </Box>
    {/* Column 3 */}
    <Box mr="28px" pb="37px" className="third-div">
      <Flex
        width="212px"
        height="140px"
        background="linear-gradient(180deg, #FFC220 0%, #CF8B05 100%)"
        borderRadius="12.59px"
        boxShadow="inset 0px -2.43439px 7.82484px 2.08662px rgba(255, 241, 189, 0.69), 0px 15.6498px 18.7798px -3.12996px rgba(28, 38, 63, 0.08), 0px 6.25993px 6.25993px -3.12996px rgba(28, 38, 63, 0.03)"
        justifyContent="center"
        mb="28px"
      >
        <Image
          src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675234990/main-web/icons/image_90_cbitu7.png"
          alt="Walmart"
          width="117px"
        />
      </Flex>
      <Flex
        width="212px"
        height="140px"
        background="linear-gradient(180deg, #424242 0%, #000101 100%)"
        borderRadius="12.59px"
        boxShadow="inset 0px -2.43439px 7.82484px 2.08662px #B5B5B5, 0px 15.6498px 18.7798px -3.12996px rgba(28, 38, 63, 0.08), 0px 6.25993px 6.25993px -3.12996px rgba(28, 38, 63, 0.03)"
        justifyContent="center"
      >
        <Image
          src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675234990/main-web/icons/image_95_fk6ftm.png"
          alt="Vialto"
          width="100px"
        />
      </Flex>
    </Box>
    {/* Column 4 */}
    <Box className="fourth-div">
      <Flex
        width="212px"
        height="140px"
        background="linear-gradient(180deg, #234291 0%, #0D1E4A 100%)"
        borderRadius="12.59px"
        boxShadow="inset 0px -2.43439px 7.82484px 2.08662px rgba(188, 219, 255, 0.69), 0px 15.6498px 18.7798px -3.12996px rgba(28, 38, 63, 0.08), 0px 6.25993px 6.25993px -3.12996px rgba(28, 38, 63, 0.03)"
        justifyContent="center"
      >
        <Image
          src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675234990/main-web/icons/image_94_quyurk.png"
          alt="General Mills"
          width="93px"
        />
      </Flex>
    </Box>
  </Flex>
);
