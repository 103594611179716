import React from "react";
import styled, { css } from "styled-components";
import CloudOnPrem from "./cloudonprem";
import FeaturesTableList from "./featurestablelist";

import { Flex, Text } from "../../globals/UIKit";
import { LinkIcon } from "../webinarvideos/eventDetails";
import { HasuraUsers } from "./HasuraUsers";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";

const StyledCloudOnPremWrapper = styled.div`
  clear: both;
  position: relative;
`;

export const PricingBlogLink = ({title, linkText, linkUrl, desc, leftConWd}) => (
  <Flex
    border="1px solid #D2D6DB"
    borderRadius="16px"
    height="96px"
    justifyContent="space-between"
    width="100%"
    maxWidth="1004px"
    margin="64px auto 0"
    padding="24px"
    alignItems="flex-start"
    css={css`
      @media (max-width: 990px) {
        min-height: 96px;
        height: auto;
        flex-direction: column;

        .blog-info-text {
          padding-left: 12px;
          margin-top: 16px;
          width: 100%;
        }
      }

      @media (max-width: 600px) {
        .announce-div {
          width: auto;
          padding-right: 12px;
        }

        .announce-label {
          display: none;
        }

        .blog-info-text {
          padding-left: 2px;
        }

        padding: 16px;
        min-height: auto;
      }
    `}
  >
    <Flex
      bg="#F3F4F6"
      borderRadius="100px"
      height="32px"
      width={leftConWd ? leftConWd : "415px"}
      padding="4px"
      className="announce-div"
    >
      <Flex
        // width="123px"
        height="24px"
        padding= "2px 10px"
        borderRadius="100px"
        bg="#fff"
        justifyContent="center"
        className="announce-label"
      >
        <Text
          fontSize="14px"
          color="#384250"
          fontWeight="500"
          lineHeight="24px"
          letterSpacing="0.005em"
        >
          {title}
        </Text>
      </Flex>
      <a href={linkUrl}>
        <Flex
          ml="14px"
          css={css`
            svg {
              min-width: 20px;
            }

            &:hover {
              p {
                color: #0079bd;
              }

              svg {
                path {
                  stroke: #0079bd;
                }
              }
            }
          `}
        >
          <Text
            color="#111927"
            fontWeight="500"
            fontSize="14px"
            lineHeight="1.5"
            letterSpacing="0.005em"
            mr="10px"
            css={css`
              @media (max-width: 500px) {
                font-size: 12px;
              }
            `}
          >
            {linkText}
          </Text>
          <LinkIcon />
        </Flex>
      </a>
    </Flex>
    <Text
      fontSize="14px"
      fontWeight="400"
      color=" #111927"
      lineHeight="1.7"
      letterSpacing="0.005em"
      width="47%"
      className="blog-info-text"
    >
      {desc}
    </Text>
  </Flex>
);

const FeatureList = () => {
  return (
    <>
      <StyledContainerWrapper isPricingPage>
        <StyledCloudOnPremWrapper>
          <CloudOnPrem />
        </StyledCloudOnPremWrapper>
        <PricingBlogLink
          title="Announcement"
          linkText="Read the blogpost for more details"
          linkUrl="http://hasura.io/blog/updates-to-hasura-pricing-and-plans"
          desc="The plans shown here reflect the new pricing and tiers that was announced on February 1, 2023."
        />
        <HasuraUsers />
        {/* <StyledSeparator css={hideBelowLg} /> */}
        <FeaturesTableList />
      </StyledContainerWrapper>
    </>
  );
};

export default FeatureList;
