import React from "react";
import { Link } from "gatsby";
import styled, { css } from "styled-components";

import LegacyStandardTableList from "./legacystandardtablelist";
import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
// import IndivFeatureList from "./indivfeaturelist";
import { PricingBlogLink } from "./featurelist";

import { StyledSubTitle2, StyledDesc1, StyledDesc3 } from "../shared/StyledTypography";
import { Icon } from "../../globals/icons";
import { scrollToTop } from "../../utils/helpers";
import { Flex, Image, Heading, Text, Box } from "../../globals/UIKit";
import { StyledTopBannerWrapper, StyledSectionWrapperLightGray } from "./topbanner";
import { StyledFeatureContainer } from "./cloudonprem";
import { legacystandardState } from "./pricingstate.js";

import { removePaddBottom } from "../shared/CommonStyled";
import { COLORS } from "../../globals/designSystem";
import { mq } from "../../globals/utils";

import help from "./images/help.svg";

const RightTick = () => (
  <Image
    src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675197052/main-web/icons/Icon_h9rnq3.svg"
    alt="Tick"
    minWidth="16px"
    width="16px"
    className="leftIcon"
  />
);

// const cloudState = [
//   {
//     imgSrc:
//       "https://res.cloudinary.com/dh8fp23nd/image/upload/v1675170349/main-web/icons/home-smile_g5blcm.svg",
//     title: "Free",
//     type: "Forever $0",
//     desc: "For experimentation, learning, and early development.",
//     variant: "grey_70",
//     list: [
//       "Connect 2 databases per project",
//       "Up to 100MB data passthrough/month",
//       "Up to 3M API requests/month",
//     ],
//     btnText: "Try free",
//     btnLink:
//       "https://cloud.hasura.io/signup?pg=pricing&plcmt=body&cta=get-started-for-free&tech=default",
//     btnVariant: "price_blue",
//     secondaryHeading: "Feature highlights",
//     extraList: [
//       "Instant GraphQL and REST APIs with role-based authorization",
//       "Premium connectors, including Snowflake, SQL Server, and more",
//     ],
//   },
// ];

const StyledFeatureMobileShow = styled.div`
  display: none;
  ${mq.below.lg} {
    display: block;
  }
`;

export const ContactUs = ({ mb }) => (
  <a href="/contact-us/" onClick={scrollToTop}>
    <Flex
      mt="0px"
      mb={mb}
      margin= "0 auto"
      width="100%"
      borderRadius="8px"
      minHeight="124px"
      height="auto"
      justifyContent="space-between"
      bg="#F0F4FF"
      padding="70px 100px"
      flexWrap="wrap"
      css={css`
        background-image: url("https://res.cloudinary.com/dh8fp23nd/image/upload/v1675938553/main-web/Mask_group_2_tgr2ei.jpg");
        background-size: cover;
        background-repeat: no-repeat;

        .right-arrow {
          display: inline-block;
          margin-top: -0.35px;
          transition: all 0.2s ease-in-out;
        }

        &:hover {
          .right-arrow {
            transform: translateX(4px);
          }
        }

        @media (max-width: 1120px) {
          padding: 40px 60px;
          margin-bottom: 100px;
        }
        @media (max-width: 500px) {
          padding: 24px 32px;
        }
        @media (max-width: 780px) {
          flex-direction: column-reverse;
          justify-content: flex-start;
          align-items: flex-start;
          margin: 60px 0;

          .banner-head {
            margin-top: 24px;
            margin-bottom: 16px;
          }
          /* padding: 10px 30px 30px; */
        }
      `}
    >
      <Box>
        <Text
          fontSize="28px"
          lineHeight="1.4"
          color="#000615"
          fontWeight="500"
          letterSpacing="-0.005em"
          mb="7px"
          className="banner-head"
          css={css`
            @media (max-width: 850px) {
              font-size: 24px;
            }
            @media (max-width: 500px) {
              font-size: 20px;
            }
          `}
        >
          Got more questions about legacy pricing?
        </Text>
        <Flex
          alignItems="center"
        >
          <Text
            fontSize="20px"
            lineHeight="1.45"
            color="#000615"
            fontWeight="400"
            letterSpacing="-0.005em"
            mr="8px"
            css={css`
              @media (max-width: 850px) {
                font-size: 18px;
              }
              @media (max-width: 500px) {
                font-size: 16px;
              }
            `}
          >
            Contact us
          </Text>
          <Icon className="right-arrow" variant="arrow_right" size="sm" color="neutral_1000" />
        </Flex>
      </Box>
      <Flex
        bg="#fff"
        borderRadius="16px"
        justifyContent="center"
        boxShadow="none"
        width="86px"
        height="86px"
      >
        <Image
          src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675271367/main-web/icons/Group_10857_vdcjbe.png"
          alt="hasura-pricing"
          minWidth="46px"
          width="46px"
        />
      </Flex>
    </Flex>
  </a>
);

const LegacyStandard = props => {
  // const cloudWrapper = cloudState.map((feature, index) => {
  //   return <IndivFeatureList key={index} features={feature} />;
  // });

  return (
    <>
      <StyledSectionWrapperLightGray css={removePaddBottom}>
        <StyledContainerWrapper>
          <StyledTopBannerWrapper>
            <Flex
              bg="#fff"
              borderRadius="16px"
              justifyContent="center"
              boxShadow="0px 12px 16px -4px rgba(0, 64, 224, 0.29), 0px 4px 6px -2px rgba(0, 58, 204, 0.03)"
              width="72px"
              height="72px"
              mb="24px"
              css={css`
                @media (max-width: 780px) {
                  margin-top: 60px;
                }
              `}
            >
              <Image
                src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675271367/main-web/icons/Group_10857_vdcjbe.png"
                alt="hasura-pricing"
                minWidth="32.84px"
                width="32.84px"
              />
            </Flex>
            <Heading
              mb="12px"
              fontSize="30px"
              fontWeight="700"
              color="#1F2A37"
              lineHeight="1.2"
              letterSpacing="-0.01em"
            >
              Hasura Cloud - Legacy Pricing Plans
            </Heading>
            <Text
              fontSize="24px"
              fontWeight="400"
              color="#111927"
              lineHeight="1.5"
              letterSpacing="-0.01em"
              width="60%"
              css={css`
                a {
                  color: ${COLORS.blue_600};
                  &:hover {
                    color: ${COLORS.blue_700};
                  }
                }
                @media (max-width: 960px) {
                  width: 85%;
                }
              `}
            >
              Reference for the legacy Standard and legacy Free plan that was deprecated as a part of the <a href="https://hasura.io/blog/updates-to-hasura-pricing-and-plans/" target="_blank" rel="noopener noreferrer">pricing changes announced</a> on February 1, 2023.
            </Text>
          </StyledTopBannerWrapper>
          {/*
          <div className="pricingPB-300"></div>
          <StyledMt500>
            {cloudWrapper}
          </StyledMt500>
          */}
        </StyledContainerWrapper>
      </StyledSectionWrapperLightGray>
      <StyledContainerWrapper>
        <PricingBlogLink
          title="Deprecation notice"
          linkText="See current pricing"
          linkUrl="https://hasura.io/pricing/"
          desc="Legacy Standard plan is only available to users that signed up for Hasura Cloud on or before January 31, 2023."
          leftConWd="330px"
        />
        <LegacyStandardTableList />
        <StyledFeatureMobileShow>
          <StyledSectionWrapper css={removePaddBottom}>
            <Flex flexDirection="column" alignItems="center" pb="20px">
              <Heading
                as="h2"
                fontWeight="700"
                color="#111927"
                fontSize="36px"
                lineHeight="1.2"
                letterSpacing="-0.015em"
              >
                Features
              </Heading>
            </Flex>
            <StyledFeatureContainer className="mWd100">
              {legacystandardState.map((features, index) => {
              return (
                <div key={index}>
                  <div className="listsWrapper">
                    <StyledSubTitle2 variant="neutral_900" fontWeight="font_bold">
                      {features.title}
                    </StyledSubTitle2>
                  </div>
                  {features.featureList.map((lists, subIndex) => {
                    return (
                      <div key={subIndex} className="listsWrapper">
                        <StyledDesc1 fontWeight="font_600" paddingBottom="pb8">
                          {lists.listTitle}
                        </StyledDesc1>
                        {lists.list.map((list, secondIndex) => {
                          return (
                            <StyledDesc3
                              key={secondIndex}
                              paddingBottom="pb8"
                              className="alignFlexStart paddMobile"
                            >
                              <RightTick className="leftIcon" />
                              <span className="flexOne">{list.featureType}</span>
                              {list.desc ? (
                                <span className="toolTipWrapper">
                                  <img className="toolTip rightIcon" src={help} alt="help" />
                                  {list.desc ? (
                                    <div className="toolTipText">
                                      <StyledDesc3 variant="grey_100">{list.desc}</StyledDesc3>
                                    </div>
                                  ) : null}
                                </span>
                              ) : null}
                            </StyledDesc3>
                          );
                        })}
                      </div>
                    )
                  })}
                </div>
              );
            })}
            </StyledFeatureContainer>
          </StyledSectionWrapper>
        </StyledFeatureMobileShow>
        <ContactUs mb="163px" />
      </StyledContainerWrapper>

    </>
  );
};

export default LegacyStandard;
