import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import IndivFeatureList from "./indivfeaturelist";
import { Flex, Text, Image } from "../../globals/UIKit";
import { StyledSubTitle2, StyledDesc1, StyledDesc2, StyledDesc3 } from "../shared/StyledTypography";
import StyledButton from "../shared/StyledButton";
import { btnWidth100 } from "../shared/CommonStyled";
import { mobileCloudState } from "./pricingstate.js";
import { mq } from "../../globals/utils";
import { COLORS, ELEVATION } from "../../globals/designSystem";
import help from "./images/help.svg";

const RightTick = () => (
  <Image
    src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675197052/main-web/icons/Icon_h9rnq3.svg"
    alt="Tick"
    minWidth="16px"
    width="16px"
    className="leftIcon"
  />
);

const StyledCloudWrapper = styled.div`
  display: grid;

  grid-template-columns: 1fr 1fr 1fr;

  grid-gap: 40px;

  padding-top: 60px;
  ${mq.between("md", "lg")} {
    grid-gap: 24px;
  }
  ${mq.below.lg} {
    display: none;
  }
`;

const StyledFeatureMobileShow = styled.div`
  display: none;
  ${mq.below.lg} {
    display: block;
  }
`;

export const StyledFeatureContainer = styled.div`
  background-color: ${COLORS.white};
  box-shadow: ${ELEVATION.l_2};
  border-radius: 8px;
  position: relative;
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  margin-top: 46px;
  &.mWd100 {
    max-width: 100%;
  }
  .featureHeader {
    background-color: ${COLORS.grey_6};
    /* background: radial-gradient(111.03% 111.03% at 44.9% -26.47%, #5c94c8 0%, #004481 100%); */
    padding: 24px 16px;
    border-radius: 8px 8px 0 0;
    position: sticky;
    top: 0px;
    z-index: 100;
    &.free {
      background: radial-gradient(111.03% 111.03% at 44.9% -26.47%, #5c94c8 0%, #004481 100%);
    }
    &.professional {
      background: radial-gradient(143.14% 143.14% at 50% -50%, #476ed0 0%, #051b55 100%);
    }
    &.enterprise {
      background: radial-gradient(152.94% 152.94% at 53.32% -19.61%, #2e3e65 0%, #000000 100%);
    }
    .positionFixed {
      position: absolute;
      right: 15px;
      top: 10px;
    }
    .displayFlex {
      display: flex;
      align-items: center;
      padding-bottom: 16px;
      img {
        width: 45px;
        margin-right: 12px;
      }
    }
  }
  .listsWrapper {
    padding: 14px 16px;
    .addiGb {
      padding-top: 24px;
    }
    .paddMobile {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .alignFlexStart {
      display: flex;
      align-items: flex-start;
      .leftIcon {
        margin-top: 6px;
      }
      .flexOne {
        flex: 1;
      }
    }
    .toolTipWrapper {
      position: relative;
      &:hover {
        .toolTipText {
          display: block;
        }
      }
      .toolTip {
        cursor: pointer;
        margin-left: 6px;
      }
      .rightIcon {
        max-width: 20px;
        width: 20px;
      }
      .toolTipText {
        display: none;
        width: 250px;
        border-radius: 8px;
        padding: 16px 24px;
        position: absolute;
        right: -15px;
        bottom: calc(100% + 10px);
        background-color: ${COLORS.white};
        color: ${COLORS.grey_100};
        z-index: 10;
        box-shadow: ${ELEVATION.l_2};
        &:before,
        &:after {
          -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
          clip-path: polygon(50% 100%, 0 0, 100% 0);
          border-top: 1px solid ${COLORS.white};
          content: "";
          position: absolute;
        }
        &:before {
          content: "";
          background-color: ${COLORS.white};
          width: 20px;
          height: 10px;
          right: 12px;
          bottom: -10px;
        }
        &:after {
          content: "";
          background-color: ${COLORS.white};
          width: 16px;
          height: 8px;
          right: 12px;
          bottom: -8px;
        }
      }
    }
  }
`;

const cloudState = [
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1675170349/main-web/icons/home-smile_g5blcm.svg",
    title: "Free",
    type: "Forever $0",
    desc: "For experimentation, learning, and early development.",
    variant: "grey_70",
    list: [
      "Connect 2 databases per project",
      "Up to 100MB data passthrough/month",
      "Up to 3M API requests/month",
    ],
    btnText: "Try free",
    btnLink:
      "https://cloud.hasura.io/signup?pg=pricing&plcmt=body&cta=get-started-for-free&tech=default",
    btnVariant: "price_blue",
    secondaryHeading: "Feature highlights",
    extraList: [
      "Instant GraphQL and REST APIs with role-based authorization",
      "Premium connectors, including Snowflake, SQL Server, and more",
    ],
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1675171039/main-web/icons/building-01_vyo6gm.svg",
    title: "Professional",
    type: "Starts at $1.50/active hour",
    desc: "For low-scale production APIs.",
    variant: "grey_70",
    // payAsYouGo: '*Pay-as-you-go',
    list: [
      "Unlimited databases per project",
      "Unlimited data passthrough at $0.13/GB",
      "Up to 6M API requests/month",
    ],
    // extraGb: "*$2 per additional GB",
    secondaryHeading: "Everything in Free, plus",
    extraList: [
      "Performance tuning (query caching and read replicas)",
      "Observability data and integrations",
      "Core security features (for eg. role-based API limits and allow lists) ",
    ],
    btnText: "Start on Free tier",
    btnLink:
      "https://cloud.hasura.io/signup?pg=pricing&plcmt=body&cta=start-on-free-tier&tech=default",
    btnVariant: "price_blue",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1675170349/main-web/icons/building-07_wzh2fa.svg",
    title: "Enterprise",
    type: "Custom pricing",
    desc: "For high-scale or mission-critical production APIs with security and compliance needs.",
    variant: "grey_70",
    list: [
      "Unlimited databases per project",
      "Unlimited data passthrough at $0.13/GB",
      "Unlimited API request/month",
    ],
    secondaryHeading: "Everything in Professional, plus",
    extraList: [
      "Dedicated infrastructure and VPC peering",
      "Unlimited query caching and read replicas",
      "Advanced security (eg. SSO) and compliance",
      "Expert 24x7 technical support, with customizable SLAs",
    ],
    btnText: "Contact us",
    btnLink: "/contact-us/",
    btnVariant: "dark_price",
    internalLink: true,
  },
];

const CloudOnPrem = () => {
  const cloudWrapper = cloudState.map((feature, index) => {
    return <IndivFeatureList key={index} features={feature} />;
  });

  const mobileCloudWrapper = mobileCloudState.map((features, index) => {
    return (
      <StyledFeatureContainer id={features.id} key={index} title={features.title}>
        <div className={`featureHeader ${[features.headerBgClass]}`}>
          <Flex
            bg="rgba(210, 214, 219, 0.3)"
            minWidth="91px"
            width="auto"
            height="28px"
            borderRadius="24px"
            justifyContent="center"
            display="inline-flex"
            px="16px"
            mb="16px"
          >
            <Image src={features.typeImg} alt="pricing-icon" width="17px" mr="8px" />
            <Text
              fontSize="14px"
              fontWeight="700"
              lineHeight="1.5"
              color="#fff"
              letterSpacing="0.005em"
            >
              {features.type}
            </Text>
          </Flex>
          <StyledDesc3 variant="white" fontWeight="font_600" className="positionFixed">
            {features.titleType}
          </StyledDesc3>
          <StyledSubTitle2 variant="white" fontWeight="font_bold" paddingBottom="pb12">
            {features.title}
          </StyledSubTitle2>
          <StyledDesc2 variant="white" paddingBottom="pb16">
            {features.desc ? features.desc : null}
          </StyledDesc2>
          <div className="buttonWrapper">
            {features.internalLink ? (
              <Link to={features.btnLink}>
                <StyledButton variant={features.btnVariant} btnWidth="width100" css={btnWidth100}>
                  {features.btnText}
                </StyledButton>
              </Link>
            ) : (
              <a href={features.btnLink}>
                <StyledButton variant={features.btnVariant} btnWidth="width100" css={btnWidth100}>
                  {features.btnText}
                </StyledButton>
              </a>
            )}
          </div>
        </div>
        <div className="listsWrapper">
          {features.list.map((list, listIndex) => {
            return (
              <StyledDesc3
                paddingBottom="pb8"
                key={listIndex}
                className="alignFlexStart paddMobile"
              >
                <Image
                  className="leftIcon"
                  src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675175990/main-web/icons/Group_11380_lsz7yj.svg"
                  alt="Tick"
                />
                {list}
              </StyledDesc3>
            );
          })}
          {features.additionalGB ? (
            <StyledDesc3 className="addiGb paddMobile">
              <i>{features.additionalGB}</i>
            </StyledDesc3>
          ) : null}
        </div>
        {features.featureList.map((lists, subIndex) => {
          return (
            <div key={subIndex} className="listsWrapper">
              <StyledDesc1 fontWeight="font_600" paddingBottom="pb8">
                {lists.listTitle}
              </StyledDesc1>
              {lists.list.map((list, secondIndex) => {
                return (
                  <StyledDesc3
                    key={secondIndex}
                    paddingBottom="pb8"
                    className="alignFlexStart paddMobile"
                  >
                    <RightTick className="leftIcon" />
                    <span className="flexOne">{list.featureType}</span>
                    {list.desc ? (
                      <span className="toolTipWrapper">
                        <img className="toolTip rightIcon" src={help} alt="help" />
                        {list.desc ? (
                          <div className="toolTipText">
                            <StyledDesc3 variant="grey_100">{list.desc}</StyledDesc3>
                          </div>
                        ) : null}
                      </span>
                    ) : null}
                  </StyledDesc3>
                );
              })}
            </div>
          );
        })}
      </StyledFeatureContainer>
    );
  });

  return (
    <>
      <StyledCloudWrapper>{cloudWrapper}</StyledCloudWrapper>
      <StyledFeatureMobileShow>{mobileCloudWrapper}</StyledFeatureMobileShow>
    </>
  );
};

export default CloudOnPrem;
